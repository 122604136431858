import React, { useState } from 'react';
import classNames from 'classnames';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import heartrateImage from 'assets/imgs/categories/heartrate.png';
import mealImage from 'assets/imgs/categories/meal.png';
import moodImage from 'assets/imgs/categories/mood.png';
import moveImage from 'assets/imgs/categories/move.png';
import sleepImage from 'assets/imgs/categories/sleep.png';
import systemImage from 'assets/imgs/categories/system.png';
import useStyles from './InsightCard.styles';

const INSIGHT_CATEGORY_IMAGES = {
  0: systemImage,
  1: moveImage,
  2: sleepImage,
  3: mealImage,
  4: moodImage,
  5: heartrateImage
};

const ObservationCard = ({ actions, insight, labels = [] }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const renderField = (field, value) => {
    if (!value) return null;

    return (
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.fieldName} variant="body1">{`${field}:`}</Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    );
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar} src={INSIGHT_CATEGORY_IMAGES[insight.category]} />
        }
        action={
          <IconButton onClick={() => setExpanded(!expanded)}>
            <ExpandMoreIcon className={classNames({ [classes.cardExpanded]: expanded })} />
          </IconButton>
        }
        title={insight.name}
        subheader={`Priority: ${insight.priority}`}
      />
      <Collapse in={expanded} timeout="auto">
        <CardContent className={classes.cardContainer}>
          {renderField('Cooldown', insight.cooldown)}
          <Typography variant="body1" gutterBottom>
            {insight.body}{' '}
          </Typography>
        </CardContent>
      </Collapse>
      <CardActions className={classes.cardActions}>
        <Button onClick={() => actions.push(`/observations/${insight.id}/edit`)}>Edit</Button>
      </CardActions>
    </Card>
  );
};

export default ObservationCard;
