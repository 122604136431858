import * as service from 'services/labels';
import {
  TYPE_REQUEST_LABELS,
  TYPE_RECEIVE_LABELS_SUCCESS,
  TYPE_RECEIVE_LABELS_CACHED,
  TYPE_RECEIVE_LABELS_ERROR,
  SHALLOW_FETCH,
  EXHAUSTIVE_FETCH,
  CACHE_BUCKET_NAME
} from 'data/labels';
import ensure from 'utils/cache';

const cache = ensure(CACHE_BUCKET_NAME);

const requestLabels = () => ({
  type: TYPE_REQUEST_LABELS
});

const receiveLabels = (fetchType, json, filter) => ({
  type: TYPE_RECEIVE_LABELS_SUCCESS,
  labels: json.data.entities,
  lastUpdated: json.meta.time,
  fetchType,
  filter: filter
});

const receiveLabelsFromCache = (fetchType, json, filter) => ({
  type: TYPE_RECEIVE_LABELS_CACHED,
  labels: json.data.entities,
  lastUpdated: json.meta.time,
  fetchType,
  filter: filter
});

const receiveLabelsError = error => ({
  type: TYPE_RECEIVE_LABELS_ERROR,
  error
});

export const fetchLabelsIfNeeded = ({ fields, facts, query }) => {
  const filter = { facts, query };
  const fetchType = fields ? SHALLOW_FETCH : EXHAUSTIVE_FETCH;

  let key = cache.makeKey(
    ...['fetch-labels-if-needed', fetchType, fields, facts, query].filter(field => !!field)
  );

  const previous = cache.get(key);

  return dispatch => {
    if (previous) {
      dispatch(receiveLabelsFromCache(fetchType, previous, filter));
      return;
    }

    dispatch(requestLabels());
    return service
      .fetchLabels(fields, facts, query)
      .then(labels => {
        cache.set(key, labels);
        dispatch(receiveLabels(fetchType, labels, filter));
      })
      .catch(reason => dispatch(receiveLabelsError(reason)));
  };
};
