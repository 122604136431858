import React from 'react';
import { TextField } from '@material-ui/core';

import useStyles from './QuestionInput.styles';

const InputTitle = props => {
  const classes = useStyles();
  return (
    <TextField
      InputProps={{
        classes: { input: classes.input }
      }}
      InputLabelProps={{
        shrink: true
      }}
      type="text"
      fullWidth
      required
      {...props}
    />
  );
};

export default InputTitle;
