import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const KeypadForm = ({ onChange, option }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={8}>
      <TextField
        autoFocus
        margin="dense"
        label="Text"
        type="text"
        value={option.text}
        onChange={onChange(`options[${option.optionKey}].text`)}
        required
        fullWidth
      />
    </Grid>
  </Grid>
);

export default KeypadForm;
