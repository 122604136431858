import React from 'react';
import classNames from 'classnames';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Paper
} from '@material-ui/core';
import { CheckCircleOutlined as CheckIcon, CancelOutlined as CancelIcon } from '@material-ui/icons';

import useStyles from './TestResults.styles';

const TestResults = ({ testPassed = false, testResults = [] }) => {
  const classes = useStyles();

  const parsedTestResults = testResults.map(result => {
    if (typeof result.warnings === 'object' && result.warnings !== null) {
      return {
        ...result,
        warnings: result.warnings.map(w => `${w.kind}: ${w.detail}`).join('/n')
      }
    }

    return result;
  });

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip
      }}
      title={
        <Paper className={classNames({ [classes.tooltipInfoContainer]: testPassed })}>
          {testPassed ? (
            <Typography variant="body1">All test passed</Typography>
          ) : (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Warnings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parsedTestResults.length &&
                  parsedTestResults.map((row, rowId) => (
                    <TableRow key={rowId}>
                      <TableCell component="th" scope="row">
                        {rowId + 1}
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.warnings}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Paper>
      }
    >
      <div className={classes.container}>
        {testPassed ? (
          <CheckIcon className={classes.testSuccess} fontSize="small" />
        ) : (
          <CancelIcon className={classes.testFailure} fontSize="small" />
        )}
        <Typography
          className={classNames({
            [classes.testSuccess]: testPassed,
            [classes.testFailure]: !testPassed
          })}
          variant="body1"
          display="inline"
        >
          {testPassed ? 'Test passed' : 'Test failed'}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default TestResults;
