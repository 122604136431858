import * as service from 'services/tracks';
import * as data from 'data/tracks';

const requestTracks = () => ({
  type: data.TYPE_REQUEST_TRACKS,
  isFetching: true
});

const receiveTracks = json => ({
  type: data.TYPE_RECEIVE_TRACKS,
  isFetching: false,
  tracks: json.tracks
});

const receiveTracksError = error => ({
  type: data.TYPE_RECEIVE_TRACKS_ERROR,
  isFetching: false,
  error: error
});

export const fetchTracks = () => dispatch => {
  dispatch(requestTracks());
  return service
    .fetchTracks()
    .then(tracks => dispatch(receiveTracks(tracks)))
    .catch(error => dispatch(receiveTracksError(error)));
};
