import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  questionContainer: {
    position: 'relative',
    padding: `0px ${theme.spacing(4)}px`
  },
  optionContainer: {
    padding: theme.spacing(2)
  },
  option: {
    padding: theme.spacing(1)
  },
  aditionalFieldsContainer: {
    margin: theme.spacing(2, 1)
  },
  selectedOption: {
    boxShadow: theme.shadows[1]
  },
  deleteOptionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
