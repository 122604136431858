import React from 'react';
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Autocomplete from 'components/commons/autocomplete';
import Button from 'components/commons/button';
import Form from 'components/commons/form';
import Select from 'components/commons/select';
import ParamsField from 'components/commons/params-field';
import ConditionsField from 'components/commons/conditions-field';
import TestsField from 'components/commons/tests-field';
import { INSIGHT_CATEGORIES, INSIGHT_STATUSES } from 'data/insights';
import useStyles from './Form.styles';

const ObservationForm = ({ facts, form, handleChange, handleCheck, labels, onSubmit }) => {
  const classes = useStyles();

  const handleSubmit = () => onSubmit();

  const makeLabelOption = label => {
    if (!label) return null;
    return {
      value: label.id,
      text: label.name
    };
  };

  const labelOptions = Object.values(labels.labels).map(makeLabelOption);
  const factOptions = facts.facts.map(fact => ({ label: fact.value }));

  return (
    <Container maxWidth="md" className={classes.formContainer}>
      <Paper>
        <Form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Select
                label="Category"
                id="category"
                onChange={handleChange('category')}
                value={form.category}
                options={INSIGHT_CATEGORIES}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                label="Status"
                id="status"
                onChange={handleChange('status')}
                value={form.status}
                options={INSIGHT_STATUSES}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="name"
                label="Name"
                type="text"
                value={form.name}
                onChange={handleChange('name')}
                margin="normal"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="priority"
                label="Priority"
                type="text"
                value={form.priority}
                onChange={handleChange('priority')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Value between 0 and 200." placement="top-start">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="ttl"
                label="Time To Live (TTL)"
                type="text"
                value={form.ttl}
                onChange={handleChange('ttl')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Amount of time in days the insight should be displayed."
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="cooldown"
                label="Cooldown"
                type="text"
                value={form.cooldown}
                onChange={handleChange('cooldown')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Amount of time in days between showing the insight for the first time, and showing it again."
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="body"
                label="Body"
                type="text"
                value={form.body}
                onChange={handleChange('body')}
                margin="normal"
                multiline
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Labels
              </Typography>
              <Typography variant="body1" gutterBottom>
                Labels contain conditions or rules that control when certain content should display
                to which users.
              </Typography>
              <Select
                label="Select labels"
                id="labels"
                onChange={handleChange('labels')}
                value={form.labels}
                options={labelOptions}
                multiple
                fullWidth
              ></Select>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Facts
              </Typography>
              <Typography variant="body1" gutterBottom>
                Add all facts to be used in the calculated parameter values.
              </Typography>
              <Autocomplete
                label="facts"
                placeholder="Search for facts"
                suggestions={factOptions}
                initialValue={form.facts}
                onChange={handleChange('facts')}
                margin="normal"
                multiple
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Parameters
              </Typography>
              <Typography variant="body1" gutterBottom>
                {
                  'Parameters will auto-fill; however, you must add formatting instructions & remove '
                }
                {'unneeded items manually.'}
              </Typography>
              <ParamsField handleChange={handleChange('params')} initialValue={form.params} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ConditionsField handleChange={handleChange} initialValue={form.conditions} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TestsField handleChange={handleChange} initialValue={form.tests} />
            </Grid>
          </Grid>
          <section className={classes.saveButtonContainer}>
            <Button color="primary" variant="contained" type="submit" size="medium">
              Save
            </Button>
          </section>
        </Form>
      </Paper>
    </Container>
  );
};

export default ObservationForm;
