import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import PledgeForm from 'components/views/pledges/form';

import useStyles from './CreatePledge.styles';

const CreatePledge = ({ actions, facts, pledge, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    name: '',
    priority: '0',
    goal: '',
    category: '1',
    title: '',
    labels: [],
    abTestNames: [],
    endTime: '0',
    startTime: '0',
    availableLangs: [],
    appId: '',
    multiDay: false,
    isLadder: false,
    rhsParams: [],
    extraParams: {},
    rhsLogic: [],
    conditions: [],
    status: '0',
    tests: {},
    description: '',
    type: '0',
    notes: '',
    authorId: '0',
    headerText: '',
    bodyText: '',
    declineText: '',
    acceptText: '',
    tenants: []
  });
  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => ({ target: { value } }) => {
    console.log({ name, value });
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleCheck = name => ({ target: { checked } }) => {
    setForm({
      ...form,
      [name]: checked
    });
  };

  const handleTenantCheck = (tenantIndex) => ({ target: { checked } }) => {
    let tenantList = form.tenants || [];
    const selectedId = tenants.tenants[tenantIndex].id;
    if(checked) {
      tenantList.push(selectedId);
    } else {
      tenantList = tenantList.filter(tenant => tenant !== selectedId);
    }
    setForm({
      ...form,
      tenants: tenantList
    });
  };

  const handleSubmit = () => {
    actions.createPledge(form).catch(console.log);
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Pledge</Typography>
      <PledgeForm
        facts={facts}
        tenants={tenants}
        form={form}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleTenantCheck={handleTenantCheck}
        labels={labels}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreatePledge;
