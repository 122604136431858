import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const PickerForm = ({ onChange, option }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={4}>
      <TextField
        autoFocus
        margin="dense"
        label="Input min"
        type="text"
        value={option.inputMin}
        onChange={onChange(`options[${option.optionKey}].inputMin`)}
        required
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        autoFocus
        margin="dense"
        label="Input max"
        type="text"
        value={option.inputMax}
        onChange={onChange(`options[${option.optionKey}].inputMax`)}
        required
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        autoFocus
        margin="dense"
        label="Input increment step"
        type="text"
        value={option.inputIncrement}
        onChange={onChange(`options[${option.optionKey}].inputIncrement`)}
        required
        fullWidth
      />
    </Grid>
  </Grid>
);

export default PickerForm;
