import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import LabelForm from 'components/views/labels/form';

import useStyles from './CreateLabel.styles';

const EditLabel = ({ actions, clusters, label, labelId, services }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  useEffect(() => {
    actions.onMount();
    actions.fetchLabel(labelId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isEmpty(label.label)) {
      const labelValues = label.label;
      const newForm = {
        name: labelValues.name,
        cluster: labelValues.cluster,
        conditions: labelValues.conditions,
        tests: labelValues.tests
      };
      setForm(newForm);
    }
  }, [label]);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = () => {
    actions.updateLabel(labelId, form).catch(console.log);
  };

  const handleCreateCluster = cluster => {
    actions.createCluster(cluster);
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Edit Label</Typography>
      {isEmpty(form) || label.isFetching ? (
        <CircularProgress />
      ) : (
        <LabelForm
          clusters={clusters}
          form={form}
          handleChange={handleChange}
          onCreateCluster={handleCreateCluster}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default EditLabel;
