import React, { useState, useEffect } from 'react';
import { Button as MUIButton, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import Sidebar from 'components/templates/sidebar';
import InsightColumn from 'components/views/insights/insight-column';
import AddColumnDialog from 'components/views/insights/add-column-dialog';
import useStyles from './Insights.styles';

const Insights = ({ actions, insights, services }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAddColumnDialog, setOpenAddColumnDialog] = useState(false);
  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateClick = event => setAnchorEl(event.currentTarget);

  const handleCloseCreate = () => setAnchorEl(null);

  const handleAddColumn = name => {
    actions.addColumn(name);
  };

  const renderContent = () => {
    const hasColumns = insights.columns.length !== 0;
    if (hasColumns) {
      return (
        <>
          {insights.columns.map(column => (
            <InsightColumn
              key={column.id}
              className={classes.insightColumn}
              classes={classes}
              column={column}
              actions={actions}
            />
          ))}
          <div className={classes.addColumnButton}>
            <IconButton onClick={() => setOpenAddColumnDialog(true)}>
              <AddIcon />
            </IconButton>
          </div>
        </>
      );
    } else {
      return (
        <div className={classes.noContentContainer}>
          <Typography variant="h4" gutterBottom>
            There's nothing to see here.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You have not added a column of insights yet, click below to add one.
          </Typography>
          <MUIButton
            className={classes.noContentButton}
            onClick={() => setOpenAddColumnDialog(true)}
            color="secondary"
          >
            Add new column
          </MUIButton>
        </div>
      );
    }
  };

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Insights"
        mainButtonAction={handleCreateClick}
        mainButtonText="Create insight"
      >
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseCreate}
        >
          <MenuItem onClick={() => actions.push('/insights/create')}>Long/Short Insight</MenuItem>
          <MenuItem onClick={() => actions.push('/observations/create')}>Observation</MenuItem>
        </Menu>
      </Sidebar>
      <div className={classes.insightsContainer}>{renderContent()}</div>
      <AddColumnDialog
        open={openAddColumnDialog}
        onClose={() => setOpenAddColumnDialog(false)}
        onSubmit={handleAddColumn}
      />
    </div>
  );
};

export default Insights;
