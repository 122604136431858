import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import LabelForm from 'components/views/labels/form';

import useStyles from './CreateLabel.styles';

const CreateLabel = ({ actions, clusters }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    name: '',
    cluster: '',
    conditions: [],
    tests: {
      conditions: [],
      params: []
    }
  });
  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = () => {
    actions.createLabel(form).catch(console.log);
  };

  const handleCreateCluster = cluster => {
    actions.createCluster(cluster);
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create New Label</Typography>
      <LabelForm
        clusters={clusters}
        form={form}
        handleChange={handleChange}
        onCreateCluster={handleCreateCluster}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateLabel;
