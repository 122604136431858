import React, { useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Routes from 'config/routes';

const App = ({ actions, isAuthed }) => {
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      actions.autoLogin();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Routes isAuthed={isAuthed} />
    </MuiPickersUtilsProvider>
  );
};

export default App;
