import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';

import { Login } from 'views';
import { login } from 'actions/auth';

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    login
  }, dispatch),
  replace
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
