import React from 'react';
import { Grid } from '@material-ui/core';

import ConditionsField from 'components/commons/conditions-field';
import TestsField from 'components/commons/tests-field';
import TenantsField from '../../../commons/tenants-field';

const ConditionsTab = ({ facts, form, handleChange, handleTenantCheck, labels, tenants }) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <ConditionsField handleChange={handleChange} initialValue={form.conditions} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TestsField handleChange={handleChange} initialValue={form.tests} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TenantsField handleTenantCheck={handleTenantCheck} initialValue={form.tenants} tenantList={tenants}/>
        </Grid>
      </Grid>
    </>
  );
};

export default ConditionsTab;
