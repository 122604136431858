import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const ShortInsightForm = ({ form, onChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="linkSource"
          label="Link source"
          type="text"
          value={form.linkSource}
          onChange={onChange('linkSource')}
          margin="normal"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default ShortInsightForm;
