import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  pageContainer: {
    marginTop: theme.spacing(8),
    height: '100%',
    width: '100%',
    overflowX: 'scroll',
    padding: theme.spacing(2)
  },
  factsContainer: {
    margin: `0 ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(8)
  },
  formattersContainer: {
    margin: `0 ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2)
  },
  table: {
    height: '500px',
    overflowX: 'scroll'
  },
  nameCell: {
    color: theme.palette.secondary.main,
    fontFamily: 'Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace'
  },
  tableHeader: {
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  spacer: {
    flex: '1 1 100%'
  }
}));
