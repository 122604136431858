import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  infoContainer: {
    whiteSpace: 'normal'
  },
  boldText: {
    fontWeight: 500
  }
}));
