import React from 'react';
import classNames from 'classnames';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

import useStyles from './DialogTemplate.styles';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
};

const DialogTemplate = ({ dialog, actions }) => {
  const classes = useStyles();

  const handleClose = () => {
    actions.dismissDialog();
  };

  if (!dialog) return null;

  const Icon = variantIcon[dialog.variant];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={true}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classNames(classes[dialog.variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {dialog.message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default DialogTemplate;
