export const stringToNumber = str => {
  if (str === '0') return 0;
  if (!isNaN(parseInt(str))) return parseInt(str);
  return null;
};

export const numberToString = num => {
  if (num === 0) return '0';
  if (num) return num.toString();
  return '';
};
