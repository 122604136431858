import React, { useState } from 'react';
import { AppBar, Container, Paper, Typography, Tab, Tabs } from '@material-ui/core';

import Button from 'components/commons/button';
import Form from 'components/commons/form';
import GeneralForm from './GeneralForm';
import QuestionsForm from './QuestionsForm';
import TestAndConditionsForm from './TestAndConditionsForm';
import useStyles from './Form.styles';

const tabs = [GeneralForm, QuestionsForm, TestAndConditionsForm];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {children}
  </Typography>
);

const SurveyForm = ({ form, handleChange, handleTenantCheck, tenants, onSubmit }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const handleSubmit = () => {
    if (form.type === '8') {
      ['introHeader', 'introBody', 'outroHeader', 'outroBody'].forEach(field =>
        handleChange(field)({ target: { value: '' } })
      );
    }
    onSubmit();
  };

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  const isSingleTypeSurvey = form.type === '8';

  return (
    <Container maxWidth="md" className={classes.formContainer}>
      <Form onSubmit={handleSubmit}>
        <Paper>
          <AppBar className={classes.tabs} elevation={0} position="static">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Questions" {...a11yProps(1)} />
              <Tab label="Tests & Conditions" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          {tabs.map((TabComponent, index) => (
            <TabPanel key={index} value={tabValue} index={index}>
              <TabComponent
                form={form}
                handleChange={handleChange}
                handleTenantCheck={handleTenantCheck}
                isSingleType={isSingleTypeSurvey}
                tenants={tenants.tenants}
              />
            </TabPanel>
          ))}
          <section className={classes.saveButtonContainer}>
            <Button color="primary" variant="contained" type="submit" size="medium">
              Save
            </Button>
          </section>
        </Paper>
      </Form>
    </Container>
  );
};

export default SurveyForm;
