import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const TranslationsTab = ({ form, handleChange }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Non-Idiomatic Content For Translators
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="headAlt"
            label="Insight header"
            type="text"
            value={form.headAlt}
            onChange={handleChange('headAlt')}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="bodyAlt"
            label="Insight body"
            type="text"
            value={form.bodyAlt}
            onChange={handleChange('bodyAlt')}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography variant="body2" display="inline">
        Wrap any parameters to be replaced with
      </Typography>
      <Typography color="secondary" variant="body2" display="inline">
        %()s.
      </Typography>
      <Typography variant="body2" display="inline">
        For example:{' '}
      </Typography>
      <Typography color="secondary" variant="body2" display="inline">
        %(m_steps_day)s.
      </Typography>
    </>
  );
};

export default TranslationsTab;
