import * as service from 'services/insights';
import {
  TYPE_UPDATE_COLUMN_FILTERS,
  TYPE_REQUEST_INSIGHTS,
  TYPE_RECEIVE_INSIGHTS,
  TYPE_RECEIVE_INSIGHTS_ERROR,
  TYPE_REQUEST_COLUMN_INSIGHTS,
  TYPE_RECEIVE_COLUMN_INSIGHTS,
  TYPE_RECEIVE_COLUMN_INSIGHTS_ERROR,
  TYPE_ADD_COLUMN,
  TYPE_REMOVE_COLUMN,
  TYPE_LOAD_COLUMNS,
  TYPE_PERSIST_COLUMNS
} from 'data/insights';

const requestColumnInsights = columnId => ({
  type: TYPE_REQUEST_COLUMN_INSIGHTS,
  columnId: columnId,
  isFetching: true
});

const receiveColumnInsights = (columnId, json) => ({
  type: TYPE_RECEIVE_COLUMN_INSIGHTS,
  isFetching: false,
  columnId: columnId,
  insights: json.data.insights,
  currentPage: json.meta.currentPage,
  nextPage: json.meta.nextPage
});

const receiveColumnInsightsError = (columnId, errorText) => ({
  type: TYPE_RECEIVE_COLUMN_INSIGHTS_ERROR,
  columnId: columnId,
  error: errorText,
  isFetching: false
});

const requestAllInsights = columnId => ({
  type: TYPE_REQUEST_INSIGHTS,
  isFetching: true
});

const receiveAllInsights = json => ({
  type: TYPE_RECEIVE_INSIGHTS,
  insights: json.data.insights,
  isFetching: false
});

const receiveAllInsightsError = error => ({
  type: TYPE_RECEIVE_INSIGHTS_ERROR,
  error: error,
  isFetching: false
});

export const fetchAllInsights = () => dispatch => {
  dispatch(requestAllInsights());
  return service
    .fetchInsights()
    .then(insights => dispatch(receiveAllInsights(insights)))
    .catch(error => dispatch(receiveAllInsightsError(error)));
};

export const fetchColumnInsights = ({ columnId, filters, page }) => dispatch => {
  dispatch(requestColumnInsights(columnId));
  return service
    .fetchInsights(filters, page)
    .then(insights => dispatch(receiveColumnInsights(columnId, insights)))
    .catch(error => dispatch(receiveColumnInsightsError(columnId, error)));
};

export const addInsightsColumn = name => ({
  type: TYPE_ADD_COLUMN,
  name
});

export const removeInsightsColumn = columnId => ({
  type: TYPE_REMOVE_COLUMN,
  columnId
});

export const updateFilters = ({ columnId, filters }) => ({
  type: TYPE_UPDATE_COLUMN_FILTERS,
  columnId,
  filters
});

export const loadColumns = () => ({ type: TYPE_LOAD_COLUMNS });

export const persistColumns = () => ({ type: TYPE_PERSIST_COLUMNS });
