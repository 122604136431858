import React from 'react';
import { Grid, FormGroup, Typography } from '@material-ui/core';
import { INSIGHT_CLIENT_TYPES } from 'data/insights';

import Select from 'components/commons/select';
import Checkbox from 'components/commons/checkbox';

const MetadataTab = ({ form, handleChange, handleCheck }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Select
            label="Client type"
            id="clientType"
            onChange={handleChange('clientType')}
            value={form.clientType}
            options={INSIGHT_CLIENT_TYPES}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Controls
          </Typography>
          <Typography variant="body1" gutterBottom>
            Tell Confucius about the situations in which this Insight should be displayed to a user.
          </Typography>
          <FormGroup>
            <Checkbox
              checked={form.isSmartCoach}
              label="Smart Coach"
              onChange={handleCheck('isSmartCoach')}
              value="isSmartCoach"
            />
            <Checkbox
              checked={form.doNotTranslate}
              label="Do Not Translate"
              onChange={handleCheck('doNotTranslate')}
              value="doNotTranslate"
            />
            <Checkbox
              checked={!!form.clientConditions}
              label="Require payments to be enabled"
              onChange={handleCheck('clientConditions')}
              value="clientConditions"
            />
            <Checkbox
              checked={form.themeAgnostic}
              label="Theme Agnostic (Ensure insight can be viewed by users currently in a segment.)"
              onChange={handleCheck('themeAgnostic')}
              value="themeAgnostic"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default MetadataTab;
