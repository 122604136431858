export const TYPE_REQUEST_TRACKS = 'tracks/REQUEST_TRACKS';
export const TYPE_RECEIVE_TRACKS = 'tracks/RECEIVE_TRACKS';
export const TYPE_RECEIVE_TRACKS_CACHED = 'tracks/RECEIVE_TRACKS-CACHED';
export const TYPE_RECEIVE_TRACKS_ERROR = 'tracks/RECEIVE_TRACKS_ERROR';

export const TYPE_REQUEST_TRACK = 'track/REQUEST_TRACK';
export const TYPE_RECEIVE_TRACK = 'track/RECEIVE_TRACK';
export const TYPE_RECEIVE_TRACK_ERROR = 'track/RECEIVE_TRACK_ERROR';
export const TYPE_UPDATE_TRACK = 'track/UPDATE_TRACK';
export const TYPE_UPDATE_TRACK_SUCCESS = 'track/UPDATE_TRACK_SUCCESS';
export const TYPE_UPDATE_TRACK_ERROR = 'track/UPDATE_TRACK_ERROR';
export const TYPE_CREATE_TRACK = 'track/CREATE_TRACK';
export const TYPE_CREATE_TRACK_SUCCESS = 'track/CREATE_TRACK_SUCCESS';
export const TYPE_CREATE_TRACK_ERROR = 'track/CREATE_TRACK_ERROR';
export const TYPE_RESET_TRACK = 'track/RESET_TRACK';


