import React from 'react';

const Form = ({ children, onSubmit, ...restProps }) =>{ 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!!onSubmit) onSubmit(e);
  };
  return (
    <form
      onSubmit={handleSubmit}
      {...restProps}
    >
      {children}
    </form>
  );
};

export default Form;
