import React, { useState, useEffect } from 'react';
import { update, constant } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import TrackForm from 'components/views/tracks/form';
import useStyles from './CreateTrack.styles';

const CreateTrack = ({ actions, insights, pledges, surveys }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    name: '',
    description: '',
    items: []
  });

  useEffect(() => {
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => ({ target: { value } }) =>
    setForm({
      ...update(form, name, constant(value))
    });

  const handleSubmit = () => {
    actions.createTrack(form);
  };

  const isFetching = insights.isFetching || pledges.isFetching || surveys.isFetching;

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Track</Typography>
      {isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <TrackForm
          {...{
            form,
            insights: insights.insights,
            pledges: pledges.pledges,
            surveys: surveys.surveys,
            handleChange,
            onSubmit: handleSubmit
          }}
        />
      )}
    </div>
  );
};

export default CreateTrack;
