import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  pageContainer: {
    paddingTop: theme.spacing(8),
    height: '100%',
    width: '100%'
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  surveysContainer: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    height: '100%',
    width: 'calc(100% - 300px)',
    overflow: 'scroll',
    padding: theme.spacing(2)
  },
  noSurveysContainer: {
    whiteSpace: 'normal'
  }
}));
