import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const ABTestingAndGroupingTab = ({ form, handleChange }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        A/B Testing
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="abTestName"
            label="A/B Test name"
            type="text"
            value={form.abTestName}
            onChange={handleChange('abTestName')}
            margin="normal"
            helperText="Important: This field must match a related config."
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="abTestSide"
            label="A/B Test side"
            type="text"
            value={form.abTestSide}
            onChange={handleChange('abTestSide')}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Grouping
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="identifier"
            label="Identifier to link insights"
            type="text"
            value={form.identifier}
            onChange={handleChange('identifier')}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Typography variant="body2" gutterBottom>
            Use if you want it to swap out with another insight on the same day. This field requires
            the input to consist of only letters, numbers, and underscores.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="groupName"
            label="Group Name"
            type="text"
            value={form.groupName}
            onChange={handleChange('groupName')}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Typography variant="body2" gutterBottom>
            Use if this is part of a group where you want the user to only receive a max of 1
            insight from the group every 7 days.
          </Typography>
        </Grid>
      </Grid>

    </>
  );
};

export default ABTestingAndGroupingTab;
