import { TYPE_REQUEST_FACTS, TYPE_RECEIVE_FACTS } from 'data/facts';

export const initState = {
  facts: [],
  lastUpdated: null,
  isFetching: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_FACTS:
      return { ...state, isFetching: true };

    case TYPE_RECEIVE_FACTS:
      return {
        ...state,
        facts: action.facts,
        lastUpdated: action.lastUpdated,
        isFetching: false
      };

    default:
      return state;
  }
};
