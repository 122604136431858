import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { Html } from 'components/commons/code';
import { useDebounce } from 'utils/hooks';
import { parseBody } from 'utils/insights';

const insightBody = body => ({
  __html: parseBody(body)
});

const LongInsightForm = ({ form, onChange }) => {
  const [body, setBody] = useState('');
  const [references, setReferences] = useState('');
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedBody = useDebounce(body, 500);
  const debouncedReferences = useDebounce(references, 500);

  useEffect(() => {
    if ((form.bodyHtml || form.referencesHtml) && !gotInitialValue) {
      setBody(form.bodyHtml);
      setReferences(form.referencesHtml);
      setGotInitialValue(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (debouncedBody) handleBodyChange();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBody]);

  useEffect(() => {
    if (debouncedReferences) handleReferencesChange();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedReferences]);

  const handleBodyChange = () =>
    onChange('bodyHtml')({
      target: { value: debouncedBody }
    });

  const handleReferencesChange = () =>
    onChange('referencesHtml')({
      target: { value: debouncedReferences }
    });

  return (
    <Box mt={1} mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption">Body HTML</Typography>
            <Html
              style={{ minHeight: '300px' }}
              name="Body HTML"
              value={body}
              onChange={value => setBody(value)}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption">Body HTML Preview</Typography>
          <Typography dangerouslySetInnerHTML={insightBody(body)} gutterBottom />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption">References HTML</Typography>
          <Html
            name="References HTML"
            value={references}
            onChange={value => setReferences(value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption">References HTML Preview</Typography>
          <Typography dangerouslySetInnerHTML={insightBody(references)} gutterBottom />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LongInsightForm;
