import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  list: {
    width: '100%'
  },
  labelCaption: {
    marginLeft: theme.spacing(2)
  }
}));
