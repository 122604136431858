import React, { useState, useEffect } from 'react';
import { update, constant, isEmpty } from 'lodash';
import {
  Container,
  Grid,
  Paper,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';

import Button from 'components/commons/button';
import Form from 'components/commons/form';
import { useDebounce } from 'utils/hooks';
import useStyles from './Form.styles';

const SurveyForm = ({ form, insights, pledges, surveys, handleChange, onSubmit }) => {
  const classes = useStyles();
  const [gotInitialSelected, setGotInitialSelected] = useState(false);
  const [selected, setSelected] = useState({
    insight: [],
    pledge: [],
    survey: []
  });
  const debouncedSelected = useDebounce(selected, 500);

  useEffect(() => {
    if (debouncedSelected) {
      const newSelected = Object.entries(debouncedSelected).flatMap(([type, ids]) =>
        ids.map(id => ({ type, id }))
      );
      handleChange('items')({ target: { value: newSelected } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSelected]);

  useEffect(() => {
    if (!(isEmpty(form) || form.items.length)) setGotInitialSelected(true);
    if (!(isEmpty(form) || gotInitialSelected)) {
      setSelected({
        insight: form.insights ?? [],
        pledge: form.pledges ?? [],
        survey: form.surveys ?? []
      });
      setGotInitialSelected(true);
    }
  }, [gotInitialSelected, form]);

  const handleSubmit = () => {
    onSubmit();
  };

  const handleToggleSelected = (type, key) => {
    const newSelected = [...selected[type]];
    const selectedPosition = newSelected.indexOf(key);
    if (selectedPosition !== -1) {
      newSelected.splice(selectedPosition, 1);
    } else {
      newSelected.push(key);
    }
    setSelected({ ...update(selected, type, constant(newSelected)) });
  };

  const renderListItem = type => (item, key) => {
    const listSelectedForType = selected[type];
    const isSelected = listSelectedForType.indexOf(item.id) !== -1;
    return (
      <ListItem
        key={key}
        button
        onClick={() => handleToggleSelected(type, item.id)}
        selected={isSelected}
      >
        <ListItemText primary={item.name} secondary={`Priority: ${item.priority}`} />
      </ListItem>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper>
        <Form onSubmit={handleSubmit} className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                type="text"
                value={form.name}
                onChange={handleChange('name')}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Description"
                type="text"
                value={form.description}
                onChange={handleChange('description')}
                margin="normal"
                rowsMax={5}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
          <section className={classes.itemsListContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6">Insights</Typography>
                <List>{insights.map(renderListItem('insight'))}</List>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6">Pledges</Typography>
                <List>{pledges.map(renderListItem('pledge'))}</List>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6">Surveys</Typography>
                <List>{surveys.map(renderListItem('survey'))}</List>
              </Grid>
            </Grid>
          </section>
          <section className={classes.saveButtonContainer}>
            <Button color="primary" variant="contained" type="submit" size="medium">
              Save
            </Button>
          </section>
        </Form>
      </Paper>
    </Container>
  );
};

export default SurveyForm;
