import React from 'react';
import { Divider, Grid, Typography, List, ListItem, ListItemText } from '@material-ui/core';

const StringsTab = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Default Parameters
          </Typography>
          <Typography variant="body2" display="inline">
            Default parameters for the pledge.
          </Typography>
          <List disablePadding>
            {['goal', 'value', 'delta', 'teammate_name'].map((value, index) => (
              <div key={index}>
                {!!index && <Divider component="li" />}
                <ListItem>
                  <ListItemText primary={value} primaryTypographyProps={{ color: 'secondary' }} />
                </ListItem>
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default StringsTab;
