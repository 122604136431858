export const sortByLastModifiedDesc = (a, b) => {
  const aDate = Date.parse(a.time_modified);
  const bDate = Date.parse(b.time_modified);

  if (aDate > bDate) {
    return -1;
  }
  if (aDate < bDate) {
    return 1;
  }
  return 0;
};
