import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField
} from '@material-ui/core';

const ClusterDialog = ({ open, value, onChange, onClose, onSubmit }) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create cluster</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Clusters are used to group related labels. Please choose a meaningful name.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Cluster name"
          type="text"
          value={value.name}
          onChange={onChange('name')}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClusterDialog;
