import isObject from 'lodash/isObject';
import * as data from 'data/pledges';

/**
 * Create a date object with added seconds
 *
 * Use this utility to populate time fields for things like pledge end time.
 *
 * @param {integer} seconds
 * @return {Date}
 */
export function secondsToDate(seconds) {
  let d = new Date(1970, 0, 1);
  d.setSeconds(seconds);
  return d;
}

/**
 * Convert date to seconds integer
 *
 * Use this utility to convert the date object from time fields to seconds
 *
 * @param {Date} date
 * @return {number}
 */
export function dateToSeconds(date) {
  let ms = date.getTime() - new Date(1970, 0, 1).getTime();
  return Math.floor(ms / data.MS_IN_SECOND);
}

/**
 * Create a date object with added minutes
 *
 * Use this utility to populate time fields for things like pledge cutoff time.
 *
 * @param {integer} minutes
 * @return {Date}
 */
export function minutesToDate(minutes) {
  let d = new Date(1970, 0, 1);
  d.setMinutes(minutes);
  return d;
}

export function minutesToDateToday(minutes) {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  d.setMinutes(minutes);
  return d;
}

/**
 * Convert date to minutes integer
 *
 * Use this utility to convert the date object from time fields to minutes
 *
 * @param {Date} date
 * @return {number}
 */
export function dateToMinutes(date) {
  let ms = date.getTime() - new Date(1970, 0, 1).getTime();
  return Math.floor(ms / data.MS_IN_MINUTE);
}

export function dateToMinutesToday(date) {
  let ms = date.getTime() - new Date().setHours(0, 0, 0 ,0);
  return Math.floor(ms / data.MS_IN_MINUTE);
}

/**
 * Returns the body value of a string or an empty String
 */
export function getStringBody(stringObject) {
  return isObject(stringObject) && stringObject.hasOwnProperty('body') ? stringObject.body : '';
}

/**
 * Groups string objects by "type"
 */
export function groupStringsByType(strings) {
  let grouped = { [data.PLEDGE_STRING_CATEGORIES.insight_card]: [] };
  for (let type of data.PLEDGE_STRING_TYPES.values()) {
    grouped[data.PLEDGE_STRING_CATEGORIES[type]] = [];
  }
  Object.keys(strings).forEach(key => {
    let matched = false;
    for (let type of data.PLEDGE_STRING_TYPES.values()) {
      if (key.includes(type)) {
        grouped[data.PLEDGE_STRING_CATEGORIES[type]].push(strings[key]);
        matched = true;
      }
    }
    if (!matched) {
      grouped[data.PLEDGE_STRING_CATEGORIES.insight_card].push(strings[key]);
    }
  });
  return grouped;
}

/**
 * Comparator function to sort string objects by their ascending content_keys
 */
export function sortByContentKey(a, b) {
  if (a.content_key < b.content_key) return -1;
  if (a.content_key > b.content_key) return 1;
  return 0;
}

/**
 * Get default, built in parameters for pledge type
 */
export function getBuiltInParamsForType(pledgeType) {
  let params = data.PLEDGE_BUILT_IN_PARAMS[pledgeType];
  return params ? Array.from(params) : Array.from(data.ANY_PARAMS);
}

/**
 * Replace periods in string names
 *
 * String names look a lot like Object properties so Formsy-react attempts to
 * map them to objects.
 */
export function formatStringContentKey(contentKey, char = ':') {
  return contentKey.replace(/\./g, char);
}

/**
 * Restore periods in string names
 */
export function unformatStringContentKey(formattedKey, char = ':') {
  return formattedKey.replace(new RegExp('\\' + char, 'g'), '.');
}

/**
 * Create a new list of pledge strings with updated string bodies
 */
export function updateStrings(pledge, newStrings) {
  return pledge.strings.map(str => ({
    ...str,
    body: newStrings[str.content_key] || str.body
  }));
}

/**
 * Return filtered array containing valid languages
 *
 * Available language field creates a string that might contain invalid lang
 * codes depending on the user.
 *
 * @param {Array|String} langs
 * @return {Array}
 */
export function filterLanguages(langs) {
  if (!Array.isArray(langs)) {
    langs = langs.split(',').map(lang => lang.trim().toLowerCase());
  }
  return langs.filter(lang => data.PLEDGE_LANGUAGES.has(lang));
}

/**
 * Formsy validation rule to ensure a list of languages are all supported
 *
 * @param {Object} values - Form values
 * @param {Array|Staring} value - value of the field
 */
export function validateSupportedLanguages(values, value) {
  if (value === null || value === undefined || value.length === 0) return true;
  if (!Array.isArray(value)) {
    value = value.split(',');
  }
  value = value.filter(lang => lang.length > 0).map(lang => lang.trim().toLowerCase());
  return value.every(lang => data.PLEDGE_LANGUAGES.has(lang));
}
