import {
  TYPE_REQUEST_SURVEYS,
  TYPE_RECEIVE_SURVEYS,
  TYPE_RECEIVE_SURVEYS_CACHED,
  TYPE_RECEIVE_SURVEYS_ERROR
} from 'data/surveys';

export const initialState = {
  surveys: [],
  isFetching: false,
  lastUpdated: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_SURVEYS:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_SURVEYS:
    case TYPE_RECEIVE_SURVEYS_CACHED:
      return {
        ...state,
        surveys: action.surveys,
        lastUpdated: action.lastUpdated,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_SURVEYS_ERROR:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      };
    default:
      return state;
  }
};
