import makeStyles from '@material-ui/styles/makeStyles';
import { green, red } from '@material-ui/core/colors';

export default makeStyles(theme => ({
  pageContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10),
    height: '100%',
    width: '100%',
    overflow: 'auto'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  testResultContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  testSuccess: {
    color: green[500]
  },
  testFailure: {
    color: red[500]
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
