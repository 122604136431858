import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import App from 'components/app';
import { autoLogin } from 'actions/auth';

const mapStateToProps = state => ({
  isAuthed: state.auth.isAuthed,
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    autoLogin
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
