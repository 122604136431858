import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchPledge, updatePledge, resetPledge } from 'actions/pledge';
import { fetchLabelsIfNeeded } from 'actions/labels';
import { fetchFactsIfNeeded } from 'actions/facts';
import { fetchTenantsIfNeeded } from 'actions/tenants';

import { EditPledge } from 'views';

const mapStateToProps = state => ({
  facts: state.facts,
  labels: state.labels,
  pledge: state.pledge,
  tenants: state.tenants
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        updatePledge,
        fetchPledge,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
      dispatch(fetchFactsIfNeeded());
      dispatch(fetchTenantsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetPledge());
    }
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  pledgeId: ownProps.match.params.id
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditPledge);
