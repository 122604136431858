import React from 'react';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Button from 'components/commons/button';
import useStyles from './Sidebar.styles';

const Sidebar = ({ children, mainButtonAction, mainButtonText, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Button color="primary" onClick={mainButtonAction} fullWidth>
        <AddIcon color="inherit" />
        {mainButtonText}
      </Button>
      {children}
    </div>
  );
};

export default Sidebar;
