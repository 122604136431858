import { authorizedAxios } from 'config/axios';
import TrackFactory from 'factories/tracks';

export const fetchTracks = () => {
  return authorizedAxios.get('/tracks').then(response => ({
    ...response,
    tracks: response.tracks.map(TrackFactory.toModel)
  }));
};

export const createTrack = track => {
  return authorizedAxios.post('/tracks', TrackFactory.toDTO(track));
};

export const updateTrack = track => {
  return authorizedAxios.put(`/tracks/${track.id}`, TrackFactory.toDTO(track));
};

export const fetchTrack = id => {
  return authorizedAxios.get(`/tracks/${id}`).then(response => ({
    ...response,
    track: TrackFactory.toModel(response.track)
  }));
};
