import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { useDebounce } from 'utils/hooks';
import useStyles from './ParamsField.styles';

const ParamsField = ({ handleChange, initialValue }) => {
  const classes = useStyles();
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const [params, setParams] = useState([]);
  const debouncedParams = useDebounce(params, 500);
  useEffect(() => {
    if (debouncedParams) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setParams(Object.entries(initialValue));
      setGotInitialValue(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleAddParam = () => {
    const newParams = [...params, ['', '']];
    setParams(newParams);
  };

  const handleNameChange = paramKey => event => {
    const newParams = params.map((param, index) => {
      if (index !== paramKey) return param;
      return [event.target.value, param[1]];
    });
    setParams(newParams);
  };

  const handleValueChange = paramKey => event => {
    const newParams = params.map((param, index) => {
      if (index !== paramKey) return param;
      return [param[0], event.target.value];
    });
    setParams(newParams);
  };

  const handleDeleteParam = paramKey => {
    const cleanedParams = params.filter((_, index) => index !== paramKey);
    setParams([...cleanedParams]);
  };

  const handleExternalChange = () => {
    handleChange({ target: { value: Object.fromEntries(debouncedParams) } });
  };

  const renderCondition = (_, paramKey) => {
    return (
      <Box key={paramKey} mt={1} mb={1}>
        <Grid key={paramKey} container spacing={2} alignItems="center">
          <Grid item xs={12} sm={5}>
            <TextField
              id={`param-name-${paramKey}`}
              type="text"
              value={params[paramKey][0]}
              onChange={handleNameChange(paramKey)}
              label="Parameter"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={`param-value-${paramKey}`}
              type="text"
              value={params[paramKey][1]}
              onChange={handleValueChange(paramKey)}
              label="Value"
              margin="normal"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.inputFont
                }
              }}
            />
          </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton onClick={() => handleDeleteParam(paramKey)} size="small">
                <ClearIcon />
              </IconButton>
            </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {params.map(renderCondition)}
      <Button onClick={() => handleAddParam()} color="secondary">
        Add Param
      </Button>
    </>
  );
};

export default ParamsField;
