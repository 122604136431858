import React, { useState, useEffect } from 'react';
import { Typography, Paper, Input, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import Table from 'components/commons/table';
import { useDebounce } from 'utils/hooks';
import useStyles from './Summary.styles';

const Summary = ({ actions, facts, formatters }) => {
  const classes = useStyles();
  const [localFacts, setLocalFacts] = useState([]);
  const [factSearch, setFactSearch] = useState('');
  const debouncedSearch = useDebounce(factSearch, 500);
  useEffect(() => {
    actions.onMount();
  }, [actions]);

  useEffect(() => {
    setLocalFacts(facts.facts);
  }, [facts]);

  useEffect(() => {
    if (!debouncedSearch) {
      setLocalFacts(facts.facts);
    } else {
      const filteredFacts = localFacts.filter(({ name }) => name.includes(debouncedSearch));
      setLocalFacts(filteredFacts);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const handleSearchFacts = e => {
    setFactSearch(e.target.value);
  };

  const formattersArray = Object.entries(formatters.formatters);

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5" gutterBottom>
        Summary
      </Typography>
      <div className={classes.formattersContainer}>
        <div className={classes.tableHeader}>
          <Typography variant="h6" id="tableTitle">
            Formatters
          </Typography>
        </div>
        <Paper className={classes.table}>
          <Table
            rowCount={formattersArray.length}
            rowGetter={({ index }) => ({
              name: formattersArray[index][0],
              caption: formattersArray[index][1] || 'No description available'
            })}
            columns={[
              {
                width: 400,
                label: 'Name',
                dataKey: 'name',
                styles: classes.nameCell
              },
              {
                label: 'Description',
                dataKey: 'caption'
              }
            ]}
          />
        </Paper>
      </div>
      <div className={classes.factsContainer}>
        <div className={classes.tableHeader}>
          <Typography variant="h6" gutterBottom>
            Facts
          </Typography>
          <div className={classes.spacer} />
          <Input
            placeholder="Search..."
            value={factSearch}
            onChange={handleSearchFacts}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
        <Paper className={classes.table}>
          <Table
            rowCount={localFacts.length}
            rowGetter={({ index }) => localFacts[index]}
            columns={[
              {
                width: 400,
                label: 'Name',
                dataKey: 'name',
                styles: classes.nameCell
              },
              {
                label: 'Description',
                dataKey: 'caption'
              }
            ]}
          />
        </Paper>
      </div>
    </div>
  );
};

export default Summary;
