import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  infoContainer: {
    display: 'flex',
    width: '100%'
  },
  descriptionSection: {
    flex: '1',
    whiteSpace: 'normal',
    marginRight: theme.spacing(1)
  },
  tableSection: {
    flex: '2'
  }
}));
