import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  pageContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10),
    height: '100%',
    width: '100%',
    overflow: 'auto'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  testContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
