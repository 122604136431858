import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(2)
  },
  titleSpacing: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));
