import { authorizedAxios } from 'config/axios';
import InsightFactory from 'factories/insights';

/**
 * Service that will request authors of insights from the factuary api
 */
export const fetchAuthors = () => {
  return authorizedAxios.get('/insights/authors');
};

/**
 * Service that will request a single insight from the factuary api
 *
 * @param {number} id - the id of the insight you wish to load
 * @return {Promise}
 */
export const fetchInsight = id => {
  return authorizedAxios.get(`/insights/${id}`).then(response => ({
    ...response,
    data: InsightFactory.toModel(response.data)
  }));
};

/**
 * Service that will get the status of insight tests from the factuary api
 *
 * @param {number} id - the id of the insight you wish to load
 * @return {Promise}
 */
export const executeTestsOnInsight = id => {
  return authorizedAxios.get(`/insights/${id}/test`);
};

/**
 * Service that will request insights from the factuary api.
 * @param {array} labels - filter insights with by list of label ids
 * @param {null} purpose - not sure what this used for, here for parity
 * @param {number} author - filter insights with the id of an author
 * @param {string} query - filter fields by an arbitrary string
 * @param {string} status - filter insights by publication status
 * @param {boolean} showAll - return all results or a limited set
 * @return {Promise}
 *
 * @todo Keep an eye on the ability to abort fetch if people start making
 *       machine gun requests.
 * @see https://github.com/whatwg/fetch/issues/27
 */
export const fetchInsights = (filters, page) => {
  const params = new URLSearchParams();

  if (filters) {
    Object.entries(filters).forEach(([type, values]) => {
      if (values.length) params.append(type, values.join('|'));
    });
  }

  if (page) params.append('page', page);
  params.append('per_page', 10);

  return authorizedAxios.get(`/insights?${params}`).then(response => ({
    meta: {
      nextPage: response.meta.next_page,
      currentPage: response.meta.page
    },
    data: {
      ...response.data,
      insights: response.data.insights.map(InsightFactory.toModel)
    }
  }));
};

/**
 * Persist an existing insight to update it's data.
 *
 * @param {object} insight - the new insight data.
 * @param {number} insight.id - will be used to identify the insight
 * @return {Promise}
 */
export const updateInsight = insight => {
  return authorizedAxios.put(`/insights/${insight.id}`, InsightFactory.toDTO(insight));
};

/**
 * Persist a new insight.
 *
 * @param {object} insight - the new insight data.
 * @return {Promise}
 */
export const createInsight = insight => {
  return authorizedAxios.post('/insights', InsightFactory.toDTO(insight));
};
