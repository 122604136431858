import React from 'react';
import MUIButton from '@material-ui/core/Button';

import useStyles from './Button.styles';

const Button = ({ children, ...restProps }) =>{ 
  const classes = useStyles();
  return (
    <MUIButton
      classes={{
        containedPrimary: classes.textSecondary
      }}
      variant="contained"
      {...restProps}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
