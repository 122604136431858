import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import SurveyForm from 'components/views/surveys/form';
import { NEW_SURVEY } from 'data/surveys';
import useStyles from './CreateSurvey.styles';

const flagsValues = {
  'condition_day': { unnecessaryFields: [] },
  'rules_based': { unnecessaryFields: ['dayCondition'] }
}

const CreateSurvey = ({ actions, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState(NEW_SURVEY);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleTenantCheck = (tenantIndex) => ({ target: { checked } }) => {
    let tenantList = form.tenants || [];
    const selectedId = tenants.tenants[tenantIndex].id;
    if(checked) {
      tenantList.push(selectedId);
    } else {
      tenantList = tenantList.filter(tenant => tenant !== selectedId);
    }
    setForm({
      ...form,
      tenants: tenantList
    });
  };

  const handleSubmit = () => {
    const { unnecessaryFields } = flagsValues[form.flags];
    unnecessaryFields.forEach(field => handleChange(field)({ target: { value: '' } }));
    actions.createSurvey(form);
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Survey</Typography>
      <SurveyForm
        handleChange={handleChange}
        form={form} onSubmit={handleSubmit}
        tenants={tenants}
        handleTenantCheck={handleTenantCheck}
      />
    </div>
  );
};

export default CreateSurvey;
