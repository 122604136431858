import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Checkbox from '../checkbox';

const TenantsField = ({ tenantList = [], initialValue = [], handleTenantCheck }) => {

  const renderCondition = (tenant, index) => {
    return (
      <Grid key={index} item xs={12} sm={8}>
        <Checkbox
          id={`tenant-${index}`}
          checked={initialValue.includes(tenant.id)}
          label={tenant.name}
          onChange={handleTenantCheck(index)}
        />
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Tenants
      </Typography>
      {tenantList.map(renderCondition)}
    </>
  );
};

export default TenantsField;
