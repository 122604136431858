import {
  TYPE_REQUEST_SURVEY,
  TYPE_RECEIVE_SURVEY,
  TYPE_RECEIVE_SURVEY_ERROR,
  TYPE_UPDATE_SURVEY,
  TYPE_UPDATE_SURVEY_SUCCESS,
  TYPE_UPDATE_SURVEY_ERROR,
  TYPE_CREATE_SURVEY,
  TYPE_CREATE_SURVEY_SUCCESS,
  TYPE_CREATE_SURVEY_ERROR,
  TYPE_RESET_SURVEY
} from 'data/surveys';

export const initialState = {
  survey: {},
  isFetching: false,
  lastUpdated: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_SURVEY:
    case TYPE_UPDATE_SURVEY:
    case TYPE_CREATE_SURVEY:
      return {
        ...state,
        isFetching: true
      };
    case TYPE_UPDATE_SURVEY_SUCCESS:
    case TYPE_CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        survey: action.survey
      };
    case TYPE_RECEIVE_SURVEY:
      return {
        ...state,
        isFetching: false,
        survey: action.survey,
        testPassed: action.testPassed,
        testResults: action.testResults
      };
    case TYPE_RECEIVE_SURVEY_ERROR:
    case TYPE_UPDATE_SURVEY_ERROR:
    case TYPE_CREATE_SURVEY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case TYPE_RESET_SURVEY:
      return { ...initialState };
    default:
      return state;
  }
};
