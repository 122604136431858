import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  LoginContainer,
  HomeContainer,
  InsightsContainer,
  CreateInsightContainer,
  EditInsightContainer,
  LabelsContainer,
  CreateLabelContainer,
  EditLabelContainer,
  PledgesContainer,
  CreatePledgeContainer,
  EditPledgeContainer,
  SurveysContainer,
  CreateSurveyContainer,
  EditSurveyContainer,
  TracksContainer,
  CreateTrackContainer,
  EditTrackContainer,
  CreateObservationContainer,
  EditObservationContainer,
  SummaryContainer
} from 'containers/views';
import { HomeTemplateContainer } from 'containers/templates';

const Routes = routerProps => {
  const { isAuthed } = routerProps;
  if (!isAuthed) {
    return <LoginContainer />;
  }
  return (
    <Switch>
      <HomeTemplateContainer>
        <Route exact path="/home" component={HomeContainer} {...routerProps} />
        <Route exact path="/insights" component={InsightsContainer} {...routerProps} />
        <Route exact path="/insights/create" component={CreateInsightContainer} {...routerProps} />
        <Route exact path="/insights/:id/edit" component={EditInsightContainer} {...routerProps} />
        <Route exact path="/labels" component={LabelsContainer} {...routerProps} />
        <Route exact path="/labels/create" component={CreateLabelContainer} {...routerProps} />
        <Route exact path="/labels/:id/edit" component={EditLabelContainer} {...routerProps} />
        <Route exact path="/pledges" component={PledgesContainer} {...routerProps} />
        <Route exact path="/pledges/create" component={CreatePledgeContainer} {...routerProps} />
        <Route exact path="/pledges/:id/edit" component={EditPledgeContainer} {...routerProps} />
        <Route exact path="/surveys" component={SurveysContainer} {...routerProps} />
        <Route exact path="/surveys/create" component={CreateSurveyContainer} {...routerProps} />
        <Route exact path="/surveys/:id/edit" component={EditSurveyContainer} {...routerProps} />
        <Route exact path="/tracks" component={TracksContainer} {...routerProps} />
        <Route exact path="/tracks/create" component={CreateTrackContainer} {...routerProps} />
        <Route exact path="/tracks/:id/edit" component={EditTrackContainer} {...routerProps} />
        <Route
          exact
          path="/observations/create"
          component={CreateObservationContainer}
          {...routerProps}
        />
        <Route
          exact
          path="/observations/:id/edit"
          component={EditObservationContainer}
          {...routerProps}
        />
        <Route exact path="/summary" component={SummaryContainer} {...routerProps} />
      </HomeTemplateContainer>
    </Switch>
  );
};

export default Routes;
