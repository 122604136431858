import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import TestConditions from './Conditions';
import TestParams from './Params';
import { useDebounce } from 'utils/hooks';
import useStyles from './TestsField.styles';

const TestsField = ({ handleChange, initialValue = {} }) => {
  const classes = useStyles();
  const [conditions, setConditions] = useState([]);
  const [params, setParams] = useState([]);
  const debouncedConditions = useDebounce(conditions, 500);
  const debouncedParams = useDebounce(params, 500);
  useEffect(() => {
    if (debouncedConditions || debouncedParams) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedConditions, debouncedParams]);

  const handleExternalChange = () => {
    const newState = {
      conditions: debouncedConditions.map(condition => ({
        ...condition,
        facts: Object.fromEntries(condition.facts),
      })),
      params: debouncedParams.map(param => ({
        expected: Object.fromEntries(param.expected),
        facts: Object.fromEntries(param.facts),
      })),
    };
    handleChange('tests')({ target: { value: newState } });
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Tests
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Tests for conditions
      </Typography>
      <TestConditions
        {...{
          classes,
          onChange: setConditions,
          ...(initialValue && { initialValue: initialValue.conditions }),
        }}
      />
      <Typography variant="subtitle1" gutterBottom>
        Tests for params
      </Typography>
      <TestParams
        {...{
          classes,
          onChange: setParams,
          ...(initialValue && { initialValue: initialValue.params }),
        }}
      />
    </>
  );
};

export default TestsField;
