import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { createLabel, resetLabel } from 'actions/label';
import { createCluster, fetchClusters } from 'actions/clusters';
import { CreateLabel } from 'views';

const mapStateToProps = state => ({
  clusters: state.labels.clusters
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createLabel,
        createCluster,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchClusters);
    },
    onUnmount: () => {
      dispatch(resetLabel());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLabel);
