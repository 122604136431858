import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  pageContainer: {
    paddingTop: theme.spacing(8),
    height: '100%',
    width: '100%'
  },
  filtersContainer: {
    verticalAlign: 'top',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    display: 'inline-block',
    height: '100%',
    width: '300px',
    padding: theme.spacing(2)
  },
  labelsContainer: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    height: '100%',
    width: 'calc(100% - 300px)',
    overflowX: 'hidden',
    padding: theme.spacing(2)
  },
  noLabelsContainer: {
    whiteSpace: 'normal',
  }
}));
