import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  pageContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10),
    height: '100%',
    width: '100%',
    overflow: 'auto'
  }
}));
