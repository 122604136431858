import axios from 'axios';
import jwtDecode from 'jwt-decode';

import env from 'config/environment';

const BASE_URL = `${env.HOST_URL}/user/api/v0.1/users`;

export const login = ({ email, password }) => {
  const credentials = btoa(`${email}:${password}`);

  return new Promise(async (resolve, reject) => {
    try {
      const { data: { token } } = await axios.post(`${BASE_URL}/@me/tokens`, {} , {
        headers: {
          Accept: 'application/json',
          Authorization: `Basic ${credentials}`,
        }
      });
      const tokenData = jwtDecode(token);
      const userData =  {
        isAuthed: true,
        authTime: Date.now(),
        uid: tokenData.id.uid,
        name: `${tokenData.id.first} ${tokenData.id.last}`,
        exp: tokenData.exp,
        perms: tokenData.prm,
        token: token
      };
      if (!userData.perms.includes('jhh_admin')) reject('Not enough permissions.');
      resolve(userData);
    } catch (error) {
      reject('Wrong credentials');
    }
  });
}

