import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchLabel, updateLabel, resetLabel } from 'actions/label';
import { createCluster, fetchClusters } from 'actions/clusters';
import { EditLabel } from 'views';

const mapStateToProps = state => ({
  label: state.label,
  clusters: state.labels.clusters
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchLabel,
        updateLabel,
        createCluster,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchClusters);
    },
    onUnmount: () => {
      dispatch(resetLabel());
    }
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  labelId: ownProps.match.params.id
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditLabel);
