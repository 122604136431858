import axios from 'axios';

import env from 'config/environment';

const unauthorizedInstance = axios.create({
  baseURL: env.HOST_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

unauthorizedInstance.interceptors.response.use(response => response.data);

const authorizedInstance = axios.create({
  baseURL: env.API_URL
});

authorizedInstance.interceptors.request.use(config => {
  return {
    ...config,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
      ...config.headers
    }
  };
});

authorizedInstance.interceptors.response.use(
  response => response.data,
  error => {
    if (error && error.data) return Promise.reject(error.data.reason);
    if (error && error.meta) return Promise.reject(error.meta.error_detail);
    if (error && error.message) return Promise.reject(error.message);
    return Promise.reject("The server couldn't process your request, please try again later.");
  }
);

export { unauthorizedInstance as unauthorizedAxios, authorizedInstance as authorizedAxios };
