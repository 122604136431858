import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchLabelsIfNeeded } from 'actions/labels';
import { fetchClusters } from 'actions/clusters';
import { copyLabel } from 'actions/label';
import { fetchFactsIfNeeded } from 'actions/facts';

import { Labels } from 'views';

const mapStateToProps = state => ({
  facts: state.facts,
  labels: state.labels
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchLabels: fetchLabelsIfNeeded,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchFactsIfNeeded());
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
      dispatch(fetchClusters);
    },
    onCopyLabel: label => {
      dispatch(copyLabel(label));
      dispatch(push('/labels/create'));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
