// Action types
export const TYPE_REQUEST_PLEDGES = 'pledges/REQUEST_PLEDGES';
export const TYPE_RECEIVE_PLEDGES = 'pledges/RECEIVE_PLEDGES';
export const TYPE_RECEIVE_PLEDGES_ERROR = 'pledges/RECEIVE_PLEDGES_ERROR';
export const TYPE_REQUEST_PLEDGE = 'pledge/REQUEST_PLEDGE';
export const TYPE_RECEIVE_PLEDGE = 'pledge/RECEIVE_PLEDGE';
export const TYPE_RECEIVE_PLEDGE_ERROR = 'pledge/RECEIVE_PLEDGE_ERROR';
export const TYPE_UPDATE_PLEDGE = 'pledge/UPDATE_PLEDGE';
export const TYPE_UPDATE_PLEDGE_SUCCESS = 'pledge/UPDATE_PLEDGE_SUCCESS';
export const TYPE_UPDATE_PLEDGE_ERROR = 'pledge/UPDATE_PLEDGE_ERROR';
export const TYPE_CREATE_PLEDGE = 'pledge/CREATE_PLEDGE';
export const TYPE_CREATE_PLEDGE_SUCCESS = 'pledge/CREATE_PLEDGE_SUCCESS';
export const TYPE_CREATE_PLEDGE_ERROR = 'pledge/CREATE_PLEDGE_ERROR';
export const TYPE_RESET_PLEDGE = 'pledge/RESET_PLEDGE';

export const PLEDGE_STATUSES = [
  { value: '0', text: 'Draft (Default)' },
  { value: '20', text: 'Live' },
  { value: '40', text: 'Deactivated' }
];

export const PLEDGE_TYPES = [
  { value: '1', text: 'Water' },
  { value: '2', text: 'Bedtime' },
  { value: '3', text: 'Steps' },
  { value: '4', text: 'Fiber' },
  { value: '5', text: 'Calorie' }
];

export const PLEDGE_CATEGORIES = [
  { value: '1', text: 'Eat' },
  { value: '2', text: 'Sleep' },
  { value: '3', text: 'Move' },
];

export const PLEDGE_PRIORITIES = [
  { value: '0', text: 'Normal' },
  { value: '1', text: 'Important 1' },
  { value: '2', text: 'Important 2' },
  { value: '3', text: 'Important 3' }
];

export const PLEDGE_LANGUAGES = new Set(['en']);

export const PLEDGE_STRING_TYPES = new Set(['in_progress', 'incomplete', 'completed']);

export const PLEDGE_STRING_CATEGORIES = {
  insight_card: 'Insight Card',
  in_progress: 'In Progress',
  incomplete: 'Failure',
  completed: 'Success'
};

export const ANY_PARAMS = new Set(['goal', 'value', 'delta', 'teammate_name']);

export const PLEDGE_BUILT_IN_PARAMS = {
  water: new Set([...ANY_PARAMS]),
  fiber: new Set([...ANY_PARAMS]),
  bedtime: new Set([...ANY_PARAMS, 'bedtime', 'time_remaining']),
  stepgoal: new Set([...ANY_PARAMS, 'steps'])
};

export const MS_IN_MINUTE = 60000;
export const MS_IN_SECOND = 1000;

export const PLEDGE_DEFAULTS = {
  category: 'sleep',
  cutoff_time: 720,
  end_time: 86399,
  status: 'draft',
  type: 'bedtime'
};
