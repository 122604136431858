import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import * as data from 'data/insights';
import * as service from 'services/insights';

const requestInsight = () => ({
  type: data.TYPE_REQUEST_INSIGHT
});

const receiveInsight = json => ({
  type: data.TYPE_RECEIVE_INSIGHT,
  insight: json.data,
  lastUpdated: json.meta.time,
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions
});

const receiveInsightError = error => ({
  type: data.TYPE_RECEIVE_INSIGHT_ERROR,
  error: error
});

const postUpdateInsight = insight => ({
  type: data.TYPE_UPDATE_INSIGHT,
  insight
});

const postUpdateInsightSuccess = (insight, json) => ({
  type: data.TYPE_UPDATE_INSIGHT_SUCCESS,
  insight: insight,
  lastUpdated: json.meta.time,
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions
});

const postUpdateInsightError = (insight, error) => ({
  type: data.TYPE_UPDATE_INSIGHT_ERROR,
  error,
  insight
});

const postCreateInsight = insight => ({
  type: data.TYPE_CREATE_INSIGHT,
  insight
});

const postCreateInsightSuccess = (insight, json) => ({
  type: data.TYPE_CREATE_INSIGHT_SUCCESS,
  lastUpdated: json.meta.time,
  insight
});

const postCreateInsightError = (insight, error) => ({
  type: data.TYPE_CREATE_INSIGHT_ERROR,
  error,
  insight
});

const requestInsightTest = () => ({
  type: data.TYPE_REQUEST_INSIGHT_TEST
});

const receiveInsightTest = json => ({
  type: data.TYPE_RECEIVE_INSIGHT_TEST,
  lastUpdated: json.meta.time
});

const receiveInsightTestError = error => ({
  type: data.TYPE_RECEIVE_INSIGHT_TEST_ERROR,
  error: error
});

export const copyInsight = insight => ({
  type: data.TYPE_COPY_INSIGHT,
  insight
});

export const resetInsight = () => ({
  type: data.TYPE_RESET_INSIGHT
});

export const fetchInsight = id => {
  return dispatch => {
    dispatch(requestInsight());
    return service
      .fetchInsight(id)
      .then(response => dispatch(receiveInsight(response)))
      .catch(error => dispatch(receiveInsightError(error)));
  };
};

export const updateInsight = insight => {
  return dispatch => {
    dispatch(postUpdateInsight(insight));
    return service
      .updateInsight(insight)
      .then(response => {
        dispatch(postUpdateInsightSuccess(insight, response));
      })
      .catch(error => dispatch(postUpdateInsightError(insight, error)));
  };
};

export const createInsight = insight => {
  return dispatch => {
    dispatch(postCreateInsight(insight));
    return service
      .createInsight(insight)
      .then(response => {
        dispatch(postCreateInsightSuccess(insight, response));
        dispatch(push('/insights'));
      })
      .catch(error => dispatch(postCreateInsightError(insight, error)));
  };
};

export const testInsight = id => {
  return dispatch => {
    dispatch(requestInsightTest());
    return service
      .executeTestsOnInsight(id)
      .then(response => dispatch(receiveInsightTest(response)))
      .catch(reason => dispatch(receiveInsightTestError(reason)));
  };
};
