export default class TrackFactory {
  static toDTO(model) {
    return {
      id: model.id,
      name: model.name,
      description: model.description,
      items: model.items.map(TrackFactory.itemToDTO)
    };
  }

  static toModel(dto) {
    return {
      id: dto.id,
      name: dto.name,
      description: dto.description,
      items: dto.items.map(TrackFactory.itemToModel),
      insights: dto.insights ?? [],
      pledges: dto.pledges ?? [],
      surveys: dto.surveys ?? []
    };
  }

  static itemToDTO(itemModel) {
    return {
      id: itemModel.id,
      type: itemModel.type
    };
  }

  static itemToModel(itemDTO) {
    return {
      id: itemDTO.id,
      name: itemDTO.name,
      priority: itemDTO.priority,
      type: itemDTO.type
    };
  }
}
