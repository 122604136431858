import {
  TYPE_REQUEST_FORMATTERS,
  TYPE_RECEIVE_FORMATTERS_SUCCESS,
  TYPE_RECEIVE_FORMATTERS_CACHED,
  TYPE_RECEIVE_FORMATTERS_ERROR
} from 'data/formatters';

export const initState = {
  formatters: {},
  isFetching: false,
  lasUpdated: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_FORMATTERS:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case TYPE_RECEIVE_FORMATTERS_SUCCESS:
    case TYPE_RECEIVE_FORMATTERS_CACHED:
      return {
        ...state,
        isFetching: false,
        formatters: action.formatters,
        lastUpdated: action.lastUpdated,
        error: null
      };
    case TYPE_RECEIVE_FORMATTERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};
