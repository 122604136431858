import * as data from 'data/pledges';
import * as service from 'services/pledges';

const requestPledges = () => ({
  type: data.TYPE_REQUEST_PLEDGES
});

const receivePledges = json => ({
  type: data.TYPE_RECEIVE_PLEDGES,
  pledges: json.data.pledges,
  lastUpdated: json.meta.time
});

const receivePledgesError = error => ({
  type: data.TYPE_RECEIVE_PLEDGES_ERROR,
  error
});

/**
 * Action creator to make an asynchronous request for pledges
 *
 * @param {number} author - filter insights with the id of an author
 * @param {string} query - filter fields by an arbitrary string
 * @param {string} status - filter insights by publication status
 */
export const fetchPledges = ({ author = '', query = '', status = '' } = {}) => dispatch => {
  dispatch(requestPledges());
  return service
    .fetchPledges(author, query, status)
    .then(pledges => dispatch(receivePledges(pledges)))
    .catch(reason => dispatch(receivePledgesError(reason)));
};
