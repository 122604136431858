import React, { useState, useEffect } from 'react';
import { update, constant } from 'lodash';
import Typography from '@material-ui/core/Typography';

import ObservationForm from 'components/views/observations/form';
import useStyles from './CreateObservation.styles';

const CreateObservation = ({ actions, facts, insights, labels }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    cooldown: '60',
    name: '',
    type: '2',
    priority: '10',
    category: '0',
    status: '0',
    body: '',
    ttl: '',
    labels: [],
    facts: [],
    params: {},
    conditions: [],
    tests: {
      conditions: [],
      params: []
    },
  });
  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => e => setForm({ ...update(form, name, constant(e.target.value)) });

  const handleCheck = name => e => setForm({ ...update(form, name, constant(e.target.checked)) });

  const handleSubmit = () => actions.createObservation(form);

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Observation</Typography>
      <ObservationForm
        facts={facts}
        form={form}
        handleChange={handleChange}
        handleCheck={handleCheck}
        labels={labels}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateObservation;
