import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  card: {
    margin: `${theme.spacing(1)}px 0px`
  },
  fieldName: {
    fontWeight: theme.typography.fontWeightBold
  },
  cardContainer: {
    maxHeight: '300px',
    overflow: 'scroll'
  },
  cardExpanded: {
    transform: 'rotate(180deg)'
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
}));
