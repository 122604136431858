import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  pageContainer: theme => ({
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10),
    height: '100%',
    width: '100%'
  })
});
