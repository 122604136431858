import { push } from 'connected-react-router';
import {
  TYPE_REQUEST_LABEL,
  TYPE_RECEIVE_LABEL_SUCCESS,
  TYPE_RECEIVE_LABEL_ERROR,
  TYPE_UPDATE_LABEL,
  TYPE_UPDATE_LABEL_SUCCESS,
  TYPE_UPDATE_LABEL_ERROR,
  TYPE_CREATE_LABEL,
  TYPE_CREATE_LABEL_SUCCESS,
  TYPE_CREATE_LABEL_ERROR,
  TYPE_COPY_LABEL,
  TYPE_RESET_LABEL,
  CACHE_BUCKET_NAME
} from 'data/labels';
import * as service from 'services/labels';
import ensure from 'utils/cache';

let cache = ensure(CACHE_BUCKET_NAME);

const requestLabel = id => ({
  type: TYPE_REQUEST_LABEL,
  label: { id }
});

const receiveLabel = json => ({
  type: TYPE_RECEIVE_LABEL_SUCCESS,
  label: json.data,
  lastUpdated: json.meta.time
});

const receiveLabelError = error => ({
  type: TYPE_RECEIVE_LABEL_ERROR,
  error: error
});

const postUpdateLabel = () => ({
  type: TYPE_UPDATE_LABEL
});

const postUpdateLabelSuccess = (label, json) => ({
  type: TYPE_UPDATE_LABEL_SUCCESS,
  lastUpdated: json.meta.time,
  label
});

const postUpdateLabelError = error => ({
  type: TYPE_UPDATE_LABEL_ERROR,
  error
});

const postCreateLabel = label => ({
  type: TYPE_CREATE_LABEL,
  label
});

const postCreateLabelSuccess = (label, json) => ({
  type: TYPE_CREATE_LABEL_SUCCESS,
  lastUpdated: json.meta.time,
  label: Object.assign({}, label, json.data)
});

const postCreateLabelError = error => ({
  type: TYPE_CREATE_LABEL_ERROR,
  error
});

/**
 * Action creator to copy an existing label
 */
export const copyLabel = label => ({
  type: TYPE_COPY_LABEL,
  label
});

/**
 * Action creator to reset label state
 */
export const resetLabel = () => ({
  type: TYPE_RESET_LABEL
});

/**
 * Action creator to fetch an existing label
 */
export const fetchLabel = id => {
  return dispatch => {
    dispatch(requestLabel(id));
    return service
      .fetchLabel(id)
      .then(response => dispatch(receiveLabel(response)))
      .catch(reason => dispatch(receiveLabelError(reason)));
  };
};

/**
 * Action creator to persist update to an existing label
 *
 * Will redirect the user back to the labels list when a label has been updated
 * successfully.
 */
export const updateLabel = (labelId, label) => {
  return dispatch => {
    dispatch(postUpdateLabel());
    return service
      .updateLabel(labelId, label)
      .then(json => {
        cache.flush();
        dispatch(postUpdateLabelSuccess(label, json));
        dispatch(push('/labels'));
      })
      .catch(reason => dispatch(postUpdateLabelError(reason)));
  };
};

/**
 * Action creator to persist a new label
 *
 * Will direct the user back to the labels list when the label has been
 * created successfully.
 */
export const createLabel = label => {
  return dispatch => {
    dispatch(postCreateLabel(label));
    return service
      .createLabel(label)
      .then(json => {
        cache.flush();
        dispatch(postCreateLabelSuccess(label, json));
        dispatch(push('/labels'));
      })
      .catch(reason => dispatch(postCreateLabelError(reason)));
  };
};
