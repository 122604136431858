import React, { useState, useEffect } from 'react';
import { capitalize, isEmpty } from 'lodash';
import {
  Button,
  Chip,
  IconButton,
  Typography,
  CircularProgress,
  Paper,
  Tooltip
} from '@material-ui/core';
import {
  RemoveCircleOutline as RemoveCircleIcon,
  Refresh as RefreshIcon,
  FilterList as FilterIcon
} from '@material-ui/icons';

import InsightCard from 'components/views/insights/insight-card';
import FilterMenu from 'components/views/insights/filter-menu';
import ObservationCard from 'components/views/observations/observation-card';
import { sortByLastModifiedDesc } from 'utils/entity';
import useStyles from './InsightColumn.styles';

const RemoveColumnButton = ({ onClick }) => (
  <Tooltip title="Remove Column" aria-label="remove-column">
    <IconButton tooltip="Remove Insight Column" onClick={onClick}>
      <RemoveCircleIcon />
    </IconButton>
  </Tooltip>
);

const RefreshColumnButton = ({ onClick }) => (
  <Tooltip title="Refresh Insights" aria-label="refresh-insights">
    <IconButton tooltip="Refresh Insights" onClick={onClick}>
      <RefreshIcon />
    </IconButton>
  </Tooltip>
);

const FilterInsightsButton = ({ id, onClick }) => (
  <Tooltip title="Filter Insights" aria-label="filter-insights">
    <IconButton id={id} tooltip="Filter Insights" onClick={onClick}>
      <FilterIcon />
    </IconButton>
  </Tooltip>
);

const InsightColumn = ({ actions, column }) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    actions.fetchInsights({ columnId: column.id, filters: column.filters });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateInsights = () => {
    actions.fetchInsights({ columnId: column.id, filters: column.filters });
  };

  const handleLoadMoreInsights = () => {
    actions.fetchInsights({ columnId: column.id, filters: column.filters, page: column.nextPage });
  };

  const handleAddFilter = newFilter => {
    const { filters } = column;
    const newFilters = {
      ...filters,
      [newFilter.type]: [...filters[newFilter.type], newFilter.value]
    };
    actions.updateFilters({ columnId: column.id, filters: newFilters });
    actions.fetchInsights({ columnId: column.id, filters: newFilters });
  };

  const handleRemoveFilter = (type, value) => () => {
    const { filters } = column;
    const newFilters = {
      ...filters,
      [type]: [...filters[type].filter(filterValue => filterValue !== value)]
    };
    actions.updateFilters({ columnId: column.id, filters: newFilters });
    actions.fetchInsights({ columnId: column.id, filters: newFilters });
  };

  const handleRemoveColumn = () => {
    actions.removeColumn(column.id);
  };

  const { insights, isFetching, error, nextPage } = column;

  const body = (() => {
    if (error)
      return (
        <>
          <Typography color="error" variant="body1">
            Woops! It looks like the server did not like our request. Here is what was returned:
          </Typography>
          <Typography color="error" variant="overline">
            {error}
          </Typography>
        </>
      );

    if (isEmpty(insights) && isFetching)
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );

    const body = insights.sort(sortByLastModifiedDesc).map((insight, index) => {
      if (insight.type === '2')
        return <ObservationCard actions={actions} key={index} insight={insight} />;
      return <InsightCard actions={actions} key={index} insight={insight} />;
    });

    if (body.length === 0) {
      return (
        <Typography variant="body1">
          While fundamentally good, there are no results for your chosen filters. Please alter your
          filters to try again.
        </Typography>
      );
    }

    if (nextPage) {
      body.push(
        <div key="load-more" className={classes.loadMoreContainer}>
          <Button color="secondary" onClick={handleLoadMoreInsights}>
            Load More
          </Button>
        </div>
      );
    }

    return body;
  })();

  const openMenu = Boolean(menuAnchorEl);
  const menuId = openMenu ? 'simple-popover' : undefined;

  const filters = Object.entries(column.filters);

  return (
    <div className={classes.insightColumn}>
      <Paper className={classes.headerContainer} elevation={1} square={true}>
        <div className={classes.titleContainer}>
          <Typography variant="h5" color="textPrimary">
            {column.name}
          </Typography>
          <section>
            <RemoveColumnButton onClick={handleRemoveColumn} />
            <RefreshColumnButton onClick={handleUpdateInsights} />
            <FilterInsightsButton id={menuId} onClick={e => setMenuAnchorEl(e.currentTarget)} />
          </section>
          <FilterMenu
            id={menuId}
            anchorEl={menuAnchorEl}
            open={openMenu}
            onClose={() => setMenuAnchorEl(null)}
            onSelected={handleAddFilter}
          />
        </div>
        {filters.length ? (
          <div>
            {filters.map(([filterType, filterValues]) =>
              filterValues.map((value, i) => (
                <Chip
                  key={i}
                  className={classes.filterChip}
                  label={`${capitalize(filterType)}: ${capitalize(value)}`}
                  onDelete={handleRemoveFilter(filterType, value)}
                />
              ))
            )}
          </div>
        ) : null}
      </Paper>
      <section className={classes.bodyContainer}>{body}</section>
    </div>
  );
};

export default InsightColumn;
