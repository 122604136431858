import React, { useState, useEffect } from 'react';
import { isEmpty, update, constant } from 'lodash';
import classNames from 'classnames';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  MoreVert as MoreIcon,
  InfoOutlined as InfoIcon,
} from '@material-ui/icons';

import Select from 'components/commons/select';
import QuestionInput from 'components/views/surveys/question-input';
import MultipleChoiceOptions from 'components/views/surveys/multiple-choice-options';
import SingleAnswerOptions from 'components/views/surveys/single-answer-options';
import { SURVEY_QUESTION_TYPES } from 'data/surveys';
import { useDebounce } from 'utils/hooks';
import useStyles from './Question.styles';

const questionTypeForms = {
  '0': { component: MultipleChoiceOptions },
  '1': { component: SingleAnswerOptions },
  '3': { component: MultipleChoiceOptions },
};

const Question = ({ onClick, onChange, onDelete, initialValue, isSelected, nextQuestion }) => {
  const classes = useStyles();
  const [hidden, setHidden] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [question, setQuestion] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedQuestion = useDebounce(question, 500);

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setQuestion(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    if (!isEmpty(debouncedQuestion)) {
      onChange(debouncedQuestion);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuestion]);

  useEffect(() => {
    setHidden(!isSelected);
    if (!isSelected) setSelectedOption(null);
  }, [isSelected]);

  const handleChange = (name) => ({ target: { value } }) =>
    setQuestion({ ...update(question, name, constant(value)) });

  const handleTypeChange = ({ target: { value } }) =>
    setQuestion({
      ...update(question, 'type', constant(value)),
      options: [
        {
          inputType: 'default',
          nextQuestion,
          position: 1,
          text: 'Option text',
          value: '',
        },
      ],
    });

  const handleOpenDialog = () => setOpenDialog(true);

  const handleSelectOption = (optionKey) => () => setSelectedOption(optionKey);

  const QuestionTypeForm = questionTypeForms[question.type] || '';

  return (
    <Paper
      className={classNames(classes.container, {
        [classes.selected]: isSelected,
      })}
      onClick={onClick}
      elevation={isSelected ? 4 : 0}
      square
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <QuestionInput
            id="question-text"
            label="Question text"
            type="text"
            value={question.text}
            onChange={handleChange('text')}
            margin="normal"
            multiline
            rowsMax="3"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Question name"
            type="text"
            value={question.name}
            onChange={handleChange('name')}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            id="type"
            label="Question type"
            onChange={handleTypeChange}
            value={question.type}
            options={SURVEY_QUESTION_TYPES}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      {!!question.type && QuestionTypeForm && (
        <QuestionTypeForm.component
          onChange={handleChange}
          options={question.options}
          nextQuestion={nextQuestion}
          onClickOption={handleSelectOption}
          selectedOption={selectedOption}
          hidden={hidden}
        />
      )}
      {!hidden && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextField
                margin="normal"
                label="Default next question"
                type="text"
                value={question.defaultNextQuestion}
                onChange={handleChange('defaultNextQuestion')}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Can be set to Outro to direct to outro instead of another question."
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.questionActionsContainer}>
            {!!onDelete && (
              <Tooltip title="Delete question">
                <IconButton onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Additional settings">
              <IconButton onClick={handleOpenDialog}>
                <MoreIcon />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )}
      {!isEmpty(question) && (
        <QuestionOptionsDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onChange={handleChange}
          question={question}
        />
      )}
    </Paper>
  );
};

const QuestionOptionsDialog = ({ open, onClose, onChange, question, questionKey }) => {
  const classes = useStyles();

  const addDisplayCondition = () => {
    const newDisplayConditions = [
      ...question.displayConditions,
      {
        questionName: '',
        answerIndices: '',
      },
    ];
    onChange('displayConditions')({ target: { value: newDisplayConditions } });
  };

  const deleteDisplayCondition = (deleteKey) => () => {
    const newDisplayConditions = question.displayConditions.filter(
      (_, conditionKey) => conditionKey !== deleteKey
    );
    onChange('displayConditions')({ target: { value: newDisplayConditions } });
  };

  const renderDisplayConditions = (condition, conditionKey) => (
    <Grid key={conditionKey} container spacing={2}>
      <Grid item xs={12} sm={5}>
        <TextField
          autoFocus
          margin="dense"
          label="Question name"
          type="text"
          value={condition.questionName}
          onChange={onChange(`displayConditions[${conditionKey}].questionName`)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="Question name the condition will be based on."
                  placement="top-start"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          autoFocus
          margin="dense"
          label="Answer indices"
          type="text"
          value={condition.answerIndices}
          onChange={onChange(`displayConditions[${conditionKey}].answerIndices`)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="Indices (positions) of the answers which will make the condition true,
                   therefore making this question the next to be shown. e.g: 1, 2, 3"
                  placement="top-start"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={1} className={classes.buttonContainer}>
        <IconButton onClick={deleteDisplayCondition(conditionKey)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{`Aditional Settings Question ${question.name}`}</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Extra Fields
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="Question position (index)"
          type="text"
          value={question?.position}
          onChange={onChange('position')}
          fullWidth
        />
        <Typography variant="h6" gutterBottom>
          Display Conditions
        </Typography>
        <Typography variant="body2" gutterBottom>
          These are used for branching inside the survey, based on answers given in previous
          questions.
        </Typography>
        {question.displayConditions?.map(renderDisplayConditions)}
      </DialogContent>
      <DialogActions>
        <Button onClick={addDisplayCondition} color="primary">
          Add display condition
        </Button>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Question;
