import { authorizedAxios } from 'config/axios';
import SurveyFactory from 'factories/surveys';

export const fetchSurveys = () => {
  return authorizedAxios.get('/surveys').then(response => ({
    ...response,
    surveys: response.surveys.map(SurveyFactory.toModel),
  }));
};

export const createSurvey = survey => {
  return authorizedAxios.post('/surveys', SurveyFactory.toDTO(survey));
};

export const updateSurvey = survey => {
  return authorizedAxios.put(`/surveys/${survey.id}`, SurveyFactory.toDTO(survey));
};

export const fetchSurvey = id => {
  return authorizedAxios.get(`/surveys/${id}`).then(response => ({
    ...response,
    survey: SurveyFactory.toModel(response.survey),
  }));
};
