import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import {
  addInsightsColumn,
  removeInsightsColumn,
  fetchColumnInsights,
  updateFilters,
  loadColumns,
  persistColumns
} from 'actions/insights';
import { fetchLabelsIfNeeded } from 'actions/labels';

import * as insightsService from 'services/insights';

import { Insights } from 'views';

const mapStateToProps = state => ({
  insights: state.insights
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchInsights: fetchColumnInsights,
        updateFilters: updateFilters,
        addColumn: addInsightsColumn,
        removeColumn: removeInsightsColumn,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
      dispatch(loadColumns());
    },
    onUnmount: () => {
      dispatch(persistColumns());
    }
  },
  services: {
    fetchAuthors: insightsService.fetchAuthors
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
