const envConstants = {
  development: {
    HOST_URL: process.env.REACT_APP_HOST_URL,
    API_URL: process.env.REACT_APP_API_URL,
    BASENAME: '',
    ENV: process.env.REACT_APP_ENV,
  },
  production: {
    HOST_URL: process.env.REACT_APP_HOST_URL,
    API_URL: process.env.REACT_APP_API_URL,
    BASENAME: '',
    ENV: process.env.REACT_APP_ENV,
  },
};

const env = envConstants[process.env.REACT_APP_ENV] || envConstants.development

export default env;
