import lscache from 'lscache'

const DEFAULT_TTL = 5 // minutes

// container for keeping track of caches
let caches = new Map()

/**
 * Interface for interacting with lscache as if each cache were a seperate
 * object instead of just a prefix.
 */
class Cache {

    /**
     * @param {string} bucket - prepend to all keys saved to this cache bucket
     * @param {number} ttl - default ttl to set on items in seconds
     */
    constructor(bucket, ttl) {
        this.bucket = bucket
        this.ttl = ttl
    }

    /**
     * Get value from this cache bucket by key
     *
     * @param {string} key
     */
    get(key) {
        lscache.setBucket(this.bucket)
        let value = lscache.get(key)
        lscache.resetBucket()
        return value
    }

    /**
     * Set value in this cache bucket
     *
     * @param {string} key
     * @param {*} value
     * @param {number} ttl - time to keep value in cache in seconds
     */
    set(key, value, ttl = this.ttl) {
        lscache.setBucket(this.bucket)
        lscache.set(key, value, ttl)
        lscache.resetBucket()
    }

    /**
     * Remove a value from this cache bucket by key
     *
     * @param {string} key
     */
    remove(key) {
        lscache.setBucket(this.bucket)
        lscache.remove(key)
        lscache.resetBucket()
    }

    /**
     * Flush all values stored in this cache bucket
     */
    flush() {
        lscache.setBucket(this.bucket)
        lscache.flush()
        lscache.resetBucket()
    }

    /**
     * Flush expired values stored in this cache bucket
     *
     * Ignore this in test coverage. I don't think it's possible to have an
     * expiry of < 1
     */
    /* istanbul ignore next */
    flushExpired() {
        lscache.setBucket(this.bucket)
        lscache.flushExpired()
        lscache.resetBucket()
    }

    /**
     * Make a key string from a list of parts.
     *
     * All parts should be strings or have a sane toString function that returns
     * something reasonable. Objects are not good candidates since toString will
     * return [object Object] which doesn't help anyone.
     */
    makeKey(...parts) {
        return parts.join('-')
    }
}

/**
 * Creates or retrieves a cache with the provided name.
 *
 * @param {string} name - The cache name / prefix you wish to retrieve
 */
function ensure(name, ttl = DEFAULT_TTL) {
    let cache = caches.get(name)
    if (cache) return cache
    cache = new Cache(name, ttl)
    caches.set(name, cache)
    return cache
}

export default ensure
