import React from 'react';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import useStyles from './Home.styles';

const Home = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Confucius Content Management System</Typography>
    </div>
  );
};

export default Home;
