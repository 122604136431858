import {
  TYPE_REQUEST_CLUSTERS,
  TYPE_RECEIVE_CLUSTERS,
  TYPE_RECEIVE_CLUSTERS_ERROR,
  TYPE_CREATE_CLUSTER,
  TYPE_CREATE_CLUSTER_SUCCESS,
  TYPE_CREATE_CLUSTER_ERROR
} from 'data/labels';
import * as service from 'services/clusters';

const requestClusters = () => ({
  type: TYPE_REQUEST_CLUSTERS
});

const receiveClusters = json => ({
  type: TYPE_RECEIVE_CLUSTERS,
  clusters: json.clusters
});

const receiveClustersError = error => ({
  type: TYPE_RECEIVE_CLUSTERS_ERROR,
  error
});

const createClusterAction = cluster => ({
  type: TYPE_CREATE_CLUSTER,
  cluster
});

const createClusterSuccessAction = (cluster, json) => ({
  type: TYPE_CREATE_CLUSTER_SUCCESS,
  clusterId: json.data,
  clusterName: cluster.name
});

const createClusterErrorAction = error => ({
  type: TYPE_CREATE_CLUSTER_ERROR,
  error
});

export const fetchClusters = dispatch => {
  dispatch(requestClusters());
  return service
    .handleFetchClusters()
    .then(json => dispatch(receiveClusters(json)))
    .catch(error => dispatch(receiveClustersError(error)));
};

export const createCluster = cluster => dispatch => {
  dispatch(createClusterAction(cluster));
  return service
    .handleCreateCluster(cluster)
    .then(json => {
      dispatch(createClusterSuccessAction(cluster, json));
    })
    .catch(error => dispatch(createClusterErrorAction(error)));
};
