import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import Checkbox from 'components/commons/checkbox';

const TracksTab = ({ form, handleCheck }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Tracks, Themes & Segments
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <Checkbox
            checked={form.segmentAnchor}
            label="Segment Anchor (Mark the insight as the entry point for a segment, requires a segment)"
            onChange={handleCheck('segmentAnchor')}
            value="segmentAnchor"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TracksTab;
