import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));
