import {
  TYPE_REQUEST_TRACK,
  TYPE_RECEIVE_TRACK,
  TYPE_RECEIVE_TRACK_ERROR,
  TYPE_UPDATE_TRACK,
  TYPE_UPDATE_TRACK_SUCCESS,
  TYPE_UPDATE_TRACK_ERROR,
  TYPE_CREATE_TRACK,
  TYPE_CREATE_TRACK_SUCCESS,
  TYPE_CREATE_TRACK_ERROR,
  TYPE_RESET_TRACK
} from 'data/tracks';

export const initialState = {
  track: {},
  isFetching: false,
  lastUpdated: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_TRACK:
    case TYPE_UPDATE_TRACK:
    case TYPE_CREATE_TRACK:
      return {
        ...state,
        isFetching: true
      };
    case TYPE_UPDATE_TRACK_SUCCESS:
    case TYPE_CREATE_TRACK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        track: action.track
      };
    case TYPE_RECEIVE_TRACK:
      return {
        ...state,
        isFetching: false,
        track: action.track,
      };
    case TYPE_RECEIVE_TRACK_ERROR:
    case TYPE_UPDATE_TRACK_ERROR:
    case TYPE_CREATE_TRACK_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case TYPE_RESET_TRACK:
      return { ...initialState };
    default:
      return state;
  }
};
