import React from 'react';
import { Grid } from '@material-ui/core';

import Checkbox from 'components/commons/checkbox';

const KeypadForm = ({ onChange, option }) => {
  const handleChange = ({ target: { checked } }) => {
    onChange(`options[${option.optionKey}].inputIs24Hr`)({ target: { value: checked } });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Checkbox label="Use 24 hrs" onChange={handleChange} />
      </Grid>
    </Grid>
  );
};

export default KeypadForm;
