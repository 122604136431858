import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 4),
    margin: theme.spacing(2, 0)
  },
  selected: {
    padding: theme.spacing(2, 4)
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  option: {
    padding: theme.spacing(1)
  },
  questionActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dialogDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
