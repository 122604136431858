import { push } from 'connected-react-router';

import * as service from 'services/tracks';
import * as data from 'data/tracks';

const requestTrack = () => ({
  type: data.TYPE_REQUEST_TRACK
});

const receiveTrack = json => ({
  type: data.TYPE_RECEIVE_TRACK,
  track: json.track
});

const receiveTrackError = error => ({
  type: data.TYPE_RECEIVE_TRACK_ERROR,
  error: error
});

const postCreateTrack = track => ({
  type: data.TYPE_CREATE_TRACK,
  track
});

const postCreateTrackSuccess = (track, json) => ({
  type: data.TYPE_CREATE_TRACK_SUCCESS,
  track
});

const postCreateTrackError = (track, error) => ({
  type: data.TYPE_CREATE_TRACK_ERROR,
  error,
  track
});

const postUpdateTrack = track => ({
  type: data.TYPE_UPDATE_TRACK,
  track
});

const postUpdateTrackSuccess = (track, json) => ({
  type: data.TYPE_UPDATE_TRACK_SUCCESS,
  track: track
});

const postUpdateTrackError = (track, error) => ({
  type: data.TYPE_UPDATE_TRACK_ERROR,
  error
});

export const resetTrack = () => ({
  type: data.TYPE_RESET_TRACK
});

export const fetchTrack = id => {
  return dispatch => {
    dispatch(requestTrack());
    return service
      .fetchTrack(id)
      .then(response => dispatch(receiveTrack(response)))
      .catch(error => dispatch(receiveTrackError(error)));
  };
};

export const createTrack = track => {
  return dispatch => {
    dispatch(postCreateTrack(track));
    return service
      .createTrack(track)
      .then(response => {
        dispatch(postCreateTrackSuccess(track, response));
        dispatch(push('/tracks'));
      })
      .catch(error => dispatch(postCreateTrackError(track, error)));
  };
};

export const updateTrack = track => {
  return dispatch => {
    dispatch(postUpdateTrack(track));
    return service
      .updateTrack(track)
      .then(response => {
        dispatch(postUpdateTrackSuccess(track, response));
        dispatch(push('/tracks'));
      })
      .catch(error => dispatch(postUpdateTrackError(track, error)));
  };
};
