import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    verticalAlign: 'top',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    display: 'inline-block',
    height: '100%',
    width: '300px',
    padding: theme.spacing(2)
  }
}));
