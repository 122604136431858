import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Autocomplete from 'components/commons/autocomplete';
import Button from 'components/commons/button';
import Sidebar from 'components/templates/sidebar';
import Cluster from './cluster';

import { groupLabelsByCluster } from 'utils/labels';
import useStyles from './Labels.styles';

const Labels = ({ actions, facts, labels }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState({
    facts: [],
    query: ''
  });
  useEffect(() => {
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => event =>
    setFilters({
      ...filters,
      [name]: event.target.value
    });

  const handleApply = () => {
    actions.fetchLabels(filters);
  };

  const suggestions = facts.facts.map(fact => ({ label: fact.value }));

  const clusters = Array.from(groupLabelsByCluster(labels.labels));
  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Labels"
        mainButtonAction={() => actions.push('/labels/create')}
        mainButtonText="Create label"
      >
        <Autocomplete
          label="Facts"
          placeholder="Search for facts"
          suggestions={suggestions}
          onChange={handleChange('facts')}
          margin="normal"
          multiple
        />
        <TextField
          id="query"
          label="Filter by query"
          value={filters.query}
          onChange={handleChange('query')}
          margin="normal"
          fullWidth
        />
        <Button color="primary" onClick={handleApply} variant="contained" size="medium">
          Apply
        </Button>
      </Sidebar>
      <div className={classes.labelsContainer}>
        {clusters.length ? (
          <>
            <Typography variant="h6" gutterBottom>
              Clusters
            </Typography>
            {clusters.map(([cluster, clusterLabels], index) => {
              const clusterName = labels.clusters[cluster];
              return (
                <Cluster key={index} {...{ actions, name: clusterName, labels: clusterLabels }} />
              );
            })}
          </>
        ) : (
          <div className={classes.noLabelsContainer}>
            <WarningIcon color="disabled" fontSize="large" />
            <Typography variant="body1">
              While fundamentally good, there are no results for your chosen filters. Please alter
              your filters to try again.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Labels;
