import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  pageContainer: {
    paddingTop: theme.spacing(8),
    height: '100%',
    width: '100%'
  },
  insightsContainer: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    height: '100%',
    width: 'calc(100% - 300px)',
    overflowX: 'scroll',
    overflowY: 'hidden'
  },
  noContentContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noContentButton: {
    marginTop: theme.spacing(2)
  },
  addColumnButton: {
    verticalAlign: 'top',
    display: 'inline-block',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '100px',
    height: '100%'
  },
  card: {
    margin: `${theme.spacing(1)}px 0px`
  }
}));
