import { connect } from 'react-redux';

import { fetchFactsIfNeeded } from 'actions/facts';
import { fetchFormatters } from 'actions/formatters';
import { Summary } from 'views';

const mapStateToProps = state => ({
  facts: state.facts,
  formatters: state.formatters
});

const mapDispatchToProps = dispatch => ({
  actions: {
    onMount: () => {
      dispatch(fetchFactsIfNeeded());
      dispatch(fetchFormatters());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
