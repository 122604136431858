import React from 'react';
import { Box, Grid } from '@material-ui/core';

import ConditionsField from 'components/commons/conditions-field';
import TestsField from 'components/commons/tests-field';
import TenantsField from '../../../commons/tenants-field';

const TestAndConditionsForm = ({ handleChange, form, handleTenantCheck, tenants }) => {
  return (
    <Box p={4}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ConditionsField handleChange={handleChange} initialValue={form.conditions} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TestsField handleChange={handleChange} initialValue={form.tests} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TenantsField handleTenantCheck={handleTenantCheck} initialValue={form.tenants} tenantList={tenants}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TestAndConditionsForm;
