import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  formContainer: {
    padding: theme.spacing(4)
  },
  itemsListContainer: {
    marginTop: theme.spacing(2)
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  }
}));
