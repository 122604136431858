import {
  TYPE_REQUEST_TRACKS,
  TYPE_RECEIVE_TRACKS,
  TYPE_RECEIVE_TRACKS_CACHED,
  TYPE_RECEIVE_TRACKS_ERROR
} from 'data/tracks';

export const initialState = {
  tracks: [],
  isFetching: false,
  lastUpdated: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_TRACKS:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_TRACKS:
    case TYPE_RECEIVE_TRACKS_CACHED:
      return {
        ...state,
        tracks: action.tracks,
        lastUpdated: action.lastUpdated,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_TRACKS_ERROR:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      };
    default:
      return state;
  }
};
