import {
  TYPE_REQUEST_FORMATTERS,
  TYPE_RECEIVE_FORMATTERS_SUCCESS,
  TYPE_RECEIVE_FORMATTERS_CACHED,
  TYPE_RECEIVE_FORMATTERS_ERROR
} from 'data/formatters';
import * as service from 'services/formatters';
import ensure from 'utils/cache';

const HOUR = 60;
const cache = ensure('formatters', HOUR);

const requestFormatters = () => ({
  type: TYPE_REQUEST_FORMATTERS
});

const receiveFormatters = json => ({
  type: TYPE_RECEIVE_FORMATTERS_SUCCESS,
  formatters: json.data,
  lastUpdated: json.meta.time
});
const receiveFormattersFromCache = json => ({
  type: TYPE_RECEIVE_FORMATTERS_CACHED,
  formatters: json.data,
  lastUpdated: json.meta.time
});

const receiveFormattersError = error => ({
  type: TYPE_RECEIVE_FORMATTERS_ERROR,
  error
});

/**
 * Action creator to request formatters from api or cache
 */
export const fetchFormatters = () => {
  const key = 'fetch-formatters';
  const previous = cache.get(key);

  return dispatch => {
    if (previous) {
      dispatch(receiveFormattersFromCache(previous));
      return;
    }

    dispatch(requestFormatters());
    return service
      .fetchFormatters()
      .then(json => {
        cache.set(key, json);
        dispatch(receiveFormatters(json));
      })
      .catch(reason => dispatch(receiveFormattersError(reason)));
  };
};
