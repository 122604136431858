import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchInsight, updateInsight, resetInsight } from 'actions/insight';
import { fetchLabelsIfNeeded } from 'actions/labels';
import { fetchFactsIfNeeded } from 'actions/facts';
import * as insightsService from 'services/insights';

import { EditObservation } from 'views';

const mapStateToProps = (state, ownProps) => ({
  facts: state.facts,
  insight: state.insight,
  labels: state.labels
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchObservation: fetchInsight,
        updateObservation: updateInsight,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetInsight());
    }
  },
  services: {
    fetchAuthors: insightsService.fetchAuthors
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  insightId: ownProps.match.params.id
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditObservation);
