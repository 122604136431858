import React from 'react';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import { AutoSizer, Column, Table as VTable } from 'react-virtualized';

import useStyles from './Table.styles';

const Table = ({
  onRowClick,
  headerHeight = 48,
  rowHeight = 48,
  columns = [],
  width,
  ...tableProps
}) => {
  const classes = useStyles();
  const getRowClassName = ({ index }) =>
    classNames(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null
    });

  const cellRenderer = ({ cellData, columnIndex }, styles) => (
    <TableCell
      component="div"
      className={classNames(classes.tableCell, classes.flexContainer, {
        [classes.noClick]: onRowClick == null,
        [styles]: !!styles
      })}
      variant="body"
      style={{ minHeight: rowHeight, height: '100%' }}
      align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
    >
      {cellData}
    </TableCell>
  );

  const headerRenderer = ({ label, columnIndex }) => (
    <TableCell
      component="div"
      className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
      variant="head"
      style={{ minHeight: headerHeight }}
      align={columns[columnIndex].numeric || false ? 'right' : 'left'}
    >
      <span>{label}</span>
    </TableCell>
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VTable
          height={height}
          width={width}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          {...tableProps}
          rowClassName={getRowClassName}
        >
          {columns.map(({ dataKey, width: cellWidth, styles, ...other }, index) => {
            const cellWidthValue = cellWidth ? cellWidth : width - 400;
            return (
              <Column
                key={dataKey}
                headerRenderer={headerProps =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: index
                  })
                }
                className={classes.flexContainer}
                cellRenderer={data => cellRenderer(data, styles)}
                dataKey={dataKey}
                width={cellWidthValue}
                {...other}
              />
            );
          })}
        </VTable>
      )}
    </AutoSizer>
  );
};

export default Table;
