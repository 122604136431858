import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, Typography } from '@material-ui/core';

import Button from 'components/commons/button';
import Form from 'components/commons/form';
import Snackbar from 'components/commons/snackbar';

import useStyles from './Login.styles';

const Login = ({ actions, user }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    email: user.email,
    password: '',
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [error, setError] = useState('');

  const handleChange = name => event =>
    setForm({
      ...form,
      [name]: event.target.value,
    });

  const handleSubmit = () => {
    actions.login(form).catch(error => {
      setError(error);
      setShowSnackbar(true);
    });
  };

  const { email, password } = form;

  return (
    <Container className={classes.container} maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <Typography variant="body1" gutterBottom>
        If one had no selfish motives, but only the supreme virtues, there would be no self&hellip; Regardless, we're
        going to ask that you identify yourself with your jawbone.com credentials.
      </Typography>
      <Form onSubmit={handleSubmit}>
        <TextField
          id="email"
          label="Email"
          type="text"
          value={email}
          onChange={handleChange('email')}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={handleChange('password')}
          margin="normal"
          fullWidth
          required
        />
        <Button color="primary" variant="contained" type="submit" size="medium">
          Login
        </Button>
      </Form>
      <Snackbar
        variant="error"
        message={error}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
      />
    </Container>
  );
};

Login.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

export default Login;
