import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DialogTemplate from 'components/templates/dialog-template';
import { dismissDialog } from 'actions/dialog';

const mapStateToProps = state => ({
  dialog: state.dialog[0]
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      dismissDialog
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogTemplate);
