import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, InputAdornment, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { useDebounce } from 'utils/hooks';
import useStyles from './ConditionsField.styles';

const ConditionsField = ({ handleChange, initialValue = [] }) => {
  const classes = useStyles();
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const [conditions, setConditions] = useState([]);
  const debouncedConditions = useDebounce(conditions, 500);
  useEffect(() => {
    if (debouncedConditions) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedConditions]);
  useEffect(() => {
    if (initialValue.length && !gotInitialValue) {
      setConditions(initialValue);
      setGotInitialValue(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleAddCondition = () => {
    const newConditions = [...conditions, ''];
    setConditions(newConditions);
  };

  const handleChangeInternal = conditionKey => event => {
    const newConditions = conditions.map((condition, index) => {
      if (index !== conditionKey) return condition;
      return event.target.value;
    });
    setConditions(newConditions);
  };

  const handleDeleteCondition = conditionKey => {
    const cleanedConditions = conditions.filter((_, index) => index !== conditionKey);
    setConditions(cleanedConditions);
  };

  const handleExternalChange = () => {
    handleChange('conditions')({ target: { value: debouncedConditions } });
  };

  const renderCondition = (_, conditionKey) => {
    return (
      <Box key={conditionKey} mt={1} mb={1}>
        <TextField
          id={`condition-${conditionKey}`}
          type="text"
          value={conditions[conditionKey]}
          onChange={handleChangeInternal(conditionKey)}
          margin="normal"
          fullWidth
          InputProps={{
            classes: {
              root: classes.inputFont,
            },
            endAdornment: (
              <InputAdornment>
                <IconButton onClick={() => handleDeleteCondition(conditionKey)}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Conditions
      </Typography>
      {conditions.map(renderCondition)}
      <Button onClick={() => handleAddCondition()} color="secondary">
        Add Condition
      </Button>
    </>
  );
};

export default ConditionsField;
