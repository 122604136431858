import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllInsights } from 'actions/insights';
import { fetchPledges } from 'actions/pledges';
import { fetchSurveys } from 'actions/surveys';
import { fetchTrack, updateTrack, resetTrack } from 'actions/track';

import { EditTrack } from 'views';

const mapStateToProps = (state, ownProps) => ({
  insights: state.insights,
  pledges: state.pledges,
  surveys: state.surveys,
  track: state.track,
  trackId: ownProps.match.params.id
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchTrack,
        updateTrack
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchAllInsights());
      dispatch(fetchPledges());
      dispatch(fetchSurveys());
    },
    onUnmount: () => {
      dispatch(resetTrack());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTrack);
