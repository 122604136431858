// Action types
export const TYPE_REQUEST_LABELS = 'labels/REQUEST_LABELS';
export const TYPE_RECEIVE_LABELS_SUCCESS = 'labels/RECEIVE_LABELS_SUCCESS';
export const TYPE_RECEIVE_LABELS_CACHED = 'labels/RECEIVE_LABELS-CACHED';
export const TYPE_RECEIVE_LABELS_ERROR = 'labels/RECEIVE_LABELS_ERROR';

export const TYPE_REQUEST_LABEL = 'label/REQUEST_LABEL';
export const TYPE_RECEIVE_LABEL_SUCCESS = 'label/RECEIVE_LABEL_SUCCESS';
export const TYPE_RECEIVE_LABEL_ERROR = 'label/RECEIVE_LABEL_ERROR';
export const TYPE_UPDATE_LABEL = 'label/UPDATE_LABEL';
export const TYPE_UPDATE_LABEL_SUCCESS = 'label/UPDATE_LABEL_SUCCESS';
export const TYPE_UPDATE_LABEL_ERROR = 'label/UPDATE_LABEL_ERROR';
export const TYPE_CREATE_LABEL = 'label/CREATE_LABEL';
export const TYPE_CREATE_LABEL_SUCCESS = 'label/CREATE_LABEL_SUCCESS';
export const TYPE_CREATE_LABEL_ERROR = 'label/CREATE_LABEL_ERROR';
export const TYPE_COPY_LABEL = 'label/COPY_LABEL';
export const TYPE_RESET_LABEL = 'label/RESET_LABEL';

export const TYPE_REQUEST_CLUSTERS = 'labels/REQUEST_CLUSTERS';
export const TYPE_RECEIVE_CLUSTERS = 'labels/RECEIVE_CLUSTERS';
export const TYPE_RECEIVE_CLUSTERS_ERROR = 'labels/RECEIVE_CLUSTERS_ERROR';
export const TYPE_CREATE_CLUSTER = 'label/CREATE_CLUSTER';
export const TYPE_CREATE_CLUSTER_SUCCESS = 'label/CREATE_CLUSTER_SUCCESS';
export const TYPE_CREATE_CLUSTER_ERROR = 'label/CREATE_CLUSTER_ERROR';

// Flag to signify that the last time labels were fetched fields were ommitted
export const SHALLOW_FETCH = 'shallow-fetch';
export const EXHAUSTIVE_FETCH = 'exhaustive-fetch';
export const FLAG_IS_LOCKED = 'is_locked';
export const CACHE_BUCKET_NAME = 'confucius-labels';
