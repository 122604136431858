import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  AppBar as MUIAppBar,
  Avatar,
  Box,
  Toolbar,
  Typography
} from '@material-ui/core';

import Button from "components/commons/button";

import logo from "assets/imgs/aristotle.png";
import useStyles from "./AppBar.styles";

const AppBar = ({ activeRoute, onClickLogout }) => {
  const classes = useStyles();

  const navOptions = [
    { text: "Insights", to: "/insights" },
    { text: "Labels", to: "/labels" },
    { text: "Pledges", to: "/pledges" },
    { text: "Surveys", to: "/surveys" },
    { text: "Tracks", to: "/tracks" },
    { text: "Summary", to: "/summary" }
  ];

  const renderButton = ({ text, to }) => {
    const isActive = activeRoute.includes(to);
    return (
      <Link
        key={text}
        to={to}
        className={classNames(classes.whiteText, classes.navigationItem, isActive && classes.activeText)}
      >
        {text}
      </Link>
    );
  };

  return (
    <MUIAppBar
      classes={{
        colorDefault: classes.defaultColor
      }}
      color="default"
      position="fixed"
    >
      <Toolbar>
        <Avatar alt="Aristotle logo" src={logo} className={classes.logo} />
        <Typography className={classNames(classes.whiteText, classes.logoText)} color="inherit" variant="h6">
          ARISTOTLE
        </Typography>
        <div className={classes.divider} />
        {navOptions.map(renderButton)}
        <Box flexGrow={1} />
        <Button color="primary" onClick={() => onClickLogout()}>
          Log out
        </Button>
      </Toolbar>
    </MUIAppBar>
  );
};

export default AppBar;
