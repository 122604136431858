import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchTracks } from 'actions/tracks';
import { Tracks } from 'views';

const mapStateToProps = state => ({
  tracks: state.tracks
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchTracks());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tracks);
