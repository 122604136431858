import React from 'react';
import { startCase } from 'lodash';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './Track.styles';

const Track = ({ actions, track }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">{track.name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.infoContainer}>
          <div className={classes.descriptionSection}>
            <Typography variant="body1" display="inline">
              {track.description}
            </Typography>
          </div>
          <div className={classes.tableSection}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Priority</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {track.items.map(row => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{startCase(row.type)}</TableCell>
                    <TableCell>{row.priority}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Button size="small" onClick={() => actions.push(`/tracks/${track.id}/edit`)}>
          Edit
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

export default Track;
