import React, { useState, useEffect, useReducer } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_INITIAL_PARAMS':
      return action.payload;
    case 'ADD_PARAM':
      return [...state, action.payload];
    case 'DELETE_PARAM':
      return state.filter((_, index) => index !== action.payload);
    case 'ADD_PARAM_FACT': {
      let localState = state;
      const { paramKey, value } = action.payload;
      const param = localState[paramKey];
      localState[paramKey]['facts'] = [...param.facts, value];
      return [...localState];
    }
    case 'DELETE_PARAM_FACT': {
      let localState = state;
      const { paramKey, factKey } = action.payload;
      const param = localState[paramKey];
      localState[paramKey]['facts'] = param.facts.filter((_, index) => index !== factKey);
      return [...localState];
    }
    case 'CHANGE_PARAM_FACT': {
      let localState = state;
      const { paramKey, factKey, factPart, value } = action.payload;
      localState[paramKey]['facts'][factKey][factPart] = value;
      return [...localState];
    }
    case 'ADD_PARAM_EXPECTED': {
      let localState = state;
      const { paramKey, value } = action.payload;
      const param = localState[paramKey];
      localState[paramKey]['expected'] = [...param.expected, value];
      return [...localState];
    }
    case 'DELETE_PARAM_EXPECTED': {
      let localState = state;
      const { paramKey, expectedKey } = action.payload;
      const param = localState[paramKey];
      localState[paramKey]['expected'] = param.expected.filter((_, index) => index !== expectedKey);
      return [...localState];
    }
    case 'CHANGE_PARAM_EXPECTED': {
      let localState = state;
      const { paramKey, expectedKey, expectedPart, value } = action.payload;
      localState[paramKey]['expected'][expectedKey][expectedPart] = value;
      return [...localState];
    }
    default:
      return state;
  }
};

const initialState = [];

const TestParams = ({ classes, initialValue, onChange }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      if (initialValue.length) {
        dispatch({
          type: 'GET_INITIAL_PARAMS',
          payload: initialValue.map(param => ({
            expected: Object.entries(param.expected),
            facts: Object.entries(param.facts),
          })),
        });
        setGotInitialValue(true);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);
  useEffect(() => {
    onChange(state);
  }, [onChange, state]);

  const handleAddParam = () => {
    const newParam = {
      expected: [['', '']],
      facts: [['', '']],
    };
    dispatch({ type: 'ADD_PARAM', payload: newParam });
  };

  const handleDeleteParam = paramKey => {
    dispatch({ type: 'DELETE_PARAM', payload: paramKey });
  };

  const handleAddFact = paramKey => {
    dispatch({ type: 'ADD_PARAM_FACT', payload: { paramKey, value: ['', ''] } });
  };

  const handleDeleteFact = (paramKey, factKey) => {
    dispatch({ type: 'DELETE_PARAM_FACT', payload: { paramKey, factKey } });
  };

  const handleFactChange = (paramKey, factKey, factPart) => ({ target: { value } }) => {
    dispatch({
      type: 'CHANGE_PARAM_FACT',
      payload: { paramKey, factKey, factPart, value },
    });
  };

  const handleAddExpected = paramKey => {
    dispatch({ type: 'ADD_PARAM_EXPECTED', payload: { paramKey, value: ['', ''] } });
  };

  const handleDeleteExpected = (paramKey, expectedKey) => {
    dispatch({ type: 'DELETE_PARAM_EXPECTED', payload: { paramKey, expectedKey } });
  };

  const handleExpectedChange = (paramKey, expectedKey, expectedPart) => ({ target: { value } }) => {
    dispatch({
      type: 'CHANGE_PARAM_EXPECTED',
      payload: { paramKey, expectedKey, expectedPart, value },
    });
  };

  const renderFact = paramKey => ([fact, value], factKey) => (
    <Grid key={factKey} container spacing={2} alignItems="center">
      <Grid item xs={12} sm={8}>
        <TextField
          id={`param-${paramKey}-fact-${factKey}`}
          type="text"
          value={fact}
          onChange={handleFactChange(paramKey, factKey, 0)}
          margin="normal"
          label="Fact"
          fullWidth
          InputProps={{
            classes: {
              root: classes.inputFont,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          id={`param-${paramKey}-fact-value-${factKey}`}
          type="text"
          value={value}
          onChange={handleFactChange(paramKey, factKey, 1)}
          margin="normal"
          label="Value"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <IconButton onClick={() => handleDeleteFact(paramKey, factKey)} size="small">
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const renderExpected = paramKey => ([expected, value], expectedKey) => (
    <Grid key={expectedKey} container spacing={2} alignItems="center">
      <Grid item xs={12} sm={8}>
        <TextField
          id={`param-${paramKey}-expected-${expectedKey}`}
          type="text"
          value={expected}
          onChange={handleExpectedChange(paramKey, expectedKey, 0)}
          margin="normal"
          label="Fact"
          fullWidth
          InputProps={{
            classes: {
              root: classes.inputFont,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          id={`param-${paramKey}-expected-value-${expectedKey}`}
          type="text"
          value={value}
          onChange={handleExpectedChange(paramKey, expectedKey, 1)}
          margin="normal"
          label="Value"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <IconButton onClick={() => handleDeleteExpected(paramKey, expectedKey)} size="small">
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const renderParam = (param, paramKey) => {
    return (
      <Box key={paramKey} mt={1} mb={1}>
        {!!param.facts.length && (
          <>
            <Typography variant="subtitle2" gutterBottom>
              Facts
            </Typography>
            {param.facts.map(renderFact(paramKey))}
          </>
        )}
        {!!param.expected.length && (
          <>
            <Typography variant="subtitle2" gutterBottom>
              Expected values
            </Typography>
            {param.expected.map(renderExpected(paramKey))}
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Button onClick={() => handleAddFact(paramKey)}>
              <AddIcon />
              Add fact
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button onClick={() => handleAddExpected(paramKey)}>
              <AddIcon />
              Add expected value
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button onClick={() => handleDeleteParam(paramKey)}>
              <ClearIcon />
              Delete param test
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {state.map(renderParam)}
      <Button onClick={() => handleAddParam()} color="secondary">
        Add Param Test
      </Button>
    </>
  );
};

export default TestParams;
