import React from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';

import Checkbox from 'components/commons/checkbox';

const NotificationsTab = ({ form, handleChange, handleCheck }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Notifications
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="watchHead"
            label="Notification header"
            type="text"
            value={form.watchHead}
            onChange={handleChange('watchHead')}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="watchBody"
            label="Notification body"
            type="text"
            value={form.watchBody}
            onChange={handleChange('watchBody')}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography variant="body2" display="inline">
        Wrap any parameters to be replaced with
      </Typography>
      <Typography color="secondary" variant="body2" display="inline">
        %()s.
      </Typography>
      <Typography variant="body2" display="inline">
        For example:{' '}
      </Typography>
      <Typography color="secondary" variant="body2" display="inline">
        %(m_steps_day)s.
      </Typography>
      <Box mt={2}>
        <Typography variant="body1">
          Check this option to use this content as the Insight notification.
        </Typography>
        <Checkbox
          checked={form.hasNotification}
          label="Display notification"
          onChange={handleCheck('hasNotification')}
          value="hasNotification"
        />
      </Box>
    </>
  );
};

export default NotificationsTab;
