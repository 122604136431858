import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchAllInsights } from 'actions/insights';
import { fetchPledges } from 'actions/pledges';
import { fetchSurveys } from 'actions/surveys';
import { createTrack } from 'actions/track';
import { CreateTrack } from 'views';

const mapStateToProps = state => ({
  insights: state.insights,
  pledges: state.pledges,
  surveys: state.surveys
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createTrack
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchAllInsights());
      dispatch(fetchPledges());
      dispatch(fetchSurveys());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTrack);
