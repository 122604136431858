import React, { useEffect } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Sidebar from 'components/templates/sidebar';
import Track from 'components/views/tracks/track';
import useStyles from './Tracks.styles';

const Tracks = ({ actions, tracks }) => {
  const classes = useStyles();
  useEffect(() => {
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Tracks"
        mainButtonAction={() => actions.push('/tracks/create')}
        mainButtonText="Create track"
      />
      <div className={classes.tracksContainer}>
        {tracks.isFetching ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : tracks.tracks.length ? (
          tracks.tracks.map((track, index) => <Track key={index} {...{ actions, track }} />)
        ) : (
          <div className={classes.noTracksContainer}>
            <WarningIcon color="disabled" fontSize="large" />
            <Typography variant="body1">
              While fundamentally good, there are no results for your chosen filters. Please alter
              your filters to try again.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tracks;
