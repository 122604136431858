import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Button from 'components/commons/button';
import Select from 'components/commons/select';
import Sidebar from 'components/templates/sidebar';
import Pledge from './pledge';
import { INSIGHT_STATUSES } from 'data/insights';
import useStyles from './Pledges.styles';

const Pledges = ({ actions, pledges }) => {
  const classes = useStyles();
  const [filtersLocal, setFilters] = useState({
    status: '',
    query: ''
  });
  useEffect(() => {
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => event =>
    setFilters({
      ...filtersLocal,
      [name]: event.target.value
    });

  const handleApply = () => actions.fetchPledges(filtersLocal);

  const { pledges: pledgesList } = pledges;
  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Pledges"
        mainButtonAction={() => actions.push('/pledges/create')}
        mainButtonText="Create pledge"
      >
        <Select
          label="Filter by status"
          id="status"
          onChange={handleChange('status')}
          value={filtersLocal.status}
          options={INSIGHT_STATUSES}
          fullWidth
        />
        <TextField
          id="query"
          label="Filter by query"
          value={filtersLocal.query}
          onChange={handleChange('query')}
          margin="normal"
          fullWidth
        />
        <Button color="primary" onClick={handleApply} variant="contained" size="medium">
          Apply
        </Button>
      </Sidebar>
      <div className={classes.pledgesContainer}>
        {pledgesList.length ? (
          <div>
            {pledgesList.map((pledge, index) => (
              <Pledge key={index} {...{ actions, pledge, index }} />
            ))}
          </div>
        ) : (
          <div className={classes.noPledgesContainer}>
            <WarningIcon color="disabled" fontSize="large" />
            <Typography variant="body1">
              While fundamentally good, there are no results for your chosen filters. Please alter
              your filters to try again.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pledges;
