import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HomeTemplate from 'components/templates/home-template';
import { logout } from 'actions/auth';

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    logout,
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTemplate);
