import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchSurvey, resetSurvey, updateSurvey } from 'actions/survey';
import { EditSurvey } from 'views';
import { fetchTenantsIfNeeded } from '../../../actions/tenants';

const mapStateToProps = (state, ownProps) => ({
  survey: state.survey,
  surveyId: ownProps.match.params.id,
  tenants: state.tenants
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchSurvey,
        updateSurvey,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchTenantsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetSurvey());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSurvey);
