import React from 'react';
import {
  Box,
  Button,
  FormGroup,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { Clips } from 'components/commons/code';
import Checkbox from 'components/commons/checkbox';
import Select from 'components/commons/select';
import ClipsTimeSwappable from './clips-time-swappable';

import { PLEDGE_LANGUAGES } from 'data/pledges';

const SettingsTab = ({ classes, form, handleCheck, handleChange, labels }) => {
  const handleTestNameChange = testNameKey => value => {
    const newRules = form.abTestNames.map((testName, index) => {
      if (index !== testNameKey) return testName;
      return value;
    });
    handleChange('abTestNames')({ target: { value: newRules } });
  };

  const handleAddTestName = () => {
    const newRules = [...form.abTestNames, ''];
    handleChange('abTestNames')({ target: { value: newRules } });
  };

  const handleDeleteTestName = ruleKey => {
    const newRules = form.abTestNames.filter((_, index) => ruleKey !== index);
    handleChange('abTestNames')({ target: { value: newRules } });
  };

  const renderTestname = (value, index) => (
    <Box key={index} display="flex" alignItems="center" mt={1} mb={1}>
      <TextField
        id={`abTestNames-${index}`}
        label="A/B Test name"
        type="text"
        value={form.abTestNames[index]}
        onChange={handleTestNameChange(index)}
        margin="normal"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => handleDeleteTestName(index)}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );

  const makeLabelOption = label => {
    if (!label) return null;
    return {
      value: label.id,
      text: label.name
    };
  };

  const labelOptions = Object.values(labels).map(makeLabelOption);
  const languagesOptions = Array.from(PLEDGE_LANGUAGES.values()).map(language => ({
    value: language,
    text: language.toUpperCase()
  }));

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Conditions
          </Typography>
          <Typography variant="body2" gutterBottom>
            Use labels to add condtions under which a user would be presented with this pledge.
          </Typography>
          <Select
            label="Labels"
            id="labels"
            onChange={handleChange('labels')}
            value={form.labels}
            options={labelOptions}
            fullWidth
            multiple
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={8}>
          {form.abTestNames && form.abTestNames.map(renderTestname)}
          <Button color="secondary" onClick={handleAddTestName}>
            Add test name
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={10}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Goal
          </Typography>
          <Typography variant="body2" gutterBottom>
            This field can contain an integer value or CLIPS logic to be evaluated.
          </Typography>
          <Clips
            name="goal"
            value={form.goal}
            onChange={value => handleChange('goal')({ target: { value } })}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={10}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Start Time
          </Typography>
          <Typography variant="body2" gutterBottom>
            {'This is the deadline for a pledge to be displayed to a user. '}
            {'This field can contain an integer value or CLIPS logic to be evaluated.'}
          </Typography>
          <ClipsTimeSwappable
            name="startTime"
            onChange={handleChange('startTime')}
            initialValue={form.startTime}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={10}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            End Time
          </Typography>
          <Typography variant="body2" gutterBottom>
            {'This is the deadline for a user to complete a pledge. '}
            {'This field can contain an integer value or CLIPS logic to be evaluated.'}
          </Typography>
          <ClipsTimeSwappable
            name="endTime"
            onChange={handleChange('endTime')}
            initialValue={form.endTime}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Select
            label="Language Whitelist"
            id="availableLangs"
            onChange={handleChange('availableLangs')}
            value={form.availableLangs}
            options={languagesOptions}
            fullWidth
            multiple
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={8}>
          <TextField
            id="appId"
            label="App ID"
            type="text"
            value={form.appId}
            onChange={handleChange('appId')}
            margin="normal"
            helperText="Defaults to Jawbone"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={8}>
          <FormGroup>
            <Checkbox
              checked={form.multiDay}
              label="Is a multi day pledge"
              onChange={handleCheck('multiDay')}
              value="multiDay"
            />
            <Checkbox
              checked={form.isLadder}
              label="Mark as ladder for analytics"
              onChange={handleCheck('isLadder')}
              value="isLadder"
            />
          </FormGroup>
          <Typography variant="body2">
            {'Laddering is controlled by Labels. '}
            {
              'Check this box to indicate if you wish to mark this as a laddered pledge for analytics.'
            }
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsTab;
