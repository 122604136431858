import React from 'react';
import { Input, InputBase, InputAdornment } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/Brightness1Outlined';

const InputTitle = ({ classes: customClasses, hidden, onDelete, ...props }) => {
  const Component = hidden ? InputBase : Input;
  return (
    <Component
      classes={{
        ...customClasses
      }}
      startAdornment={
        <InputAdornment position="start">
          <CircleIcon fontSize="small" />
        </InputAdornment>
      }
      type="text"
      fullWidth
      required
      {...props}
    />
  );
};

export default InputTitle;
