import React from 'react';
import {
  Avatar,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import mealImage from 'assets/imgs/categories/meal.png';
import sleepImage from 'assets/imgs/categories/sleep.png';
import moveImage from 'assets/imgs/categories/move.png';
import useStyles from './Pledge.styles';

const PLEDGES_CATEGORY_IMAGES = {
  1: mealImage,
  2: sleepImage,
  3: moveImage
};

const Pledge = ({ actions, pledge, index }) => {
  const classes = useStyles();
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Avatar src={PLEDGES_CATEGORY_IMAGES[pledge.category]} />
        <div className={classes.titleContainer}>
          <Typography variant="body1">{pledge.name}</Typography>
          <Typography
            variant="body2"
            color="textSecondary"
          >{`Priority: ${pledge.priority}`}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <Typography variant="body1">{pledge.description}</Typography>
          <Typography variant="body2">{`Goal: ${pledge.goal}`}</Typography>
        </div>
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Button size="small" onClick={() => actions.push(`/pledges/${pledge.id}/edit`)}>
          Edit
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

export default Pledge;
