import React from 'react';
import { Input } from '@material-ui/core';

import useStyles from './TitleInput.styles';

const InputTitle = props => {
  const classes = useStyles();
  return (
    <Input
      classes={{
        input: classes.input
      }}
      type="text"
      fullWidth
      required
      {...props}
    />
  );
};

export default InputTitle;
