import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(theme => ({
  formContainer: {
    paddingTop: theme.spacing(2)
  },
  questionsSection: {
    margin: theme.spacing(2, 0),
  },
  saveButtonContainer: {
    padding: theme.spacing(0, 4, 4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
