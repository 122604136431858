import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchSurveys } from 'actions/surveys';
import { Surveys } from 'views';

const mapStateToProps = state => ({
  surveys: state.surveys
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchSurveys());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Surveys);
