import React, { useState, useEffect } from 'react';
import { update, constant, isEmpty } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import TrackForm from 'components/views/tracks/form';
import useStyles from './EditTrack.styles';

const EditTrack = ({ actions, insights, pledges, surveys, track, trackId }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});

  useEffect(() => {
    actions.onMount();
    actions.fetchTrack(trackId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(track.track)) setForm(track.track);
  }, [track]);

  const handleChange = name => ({ target: { value } }) =>
    setForm({
      ...update(form, name, constant(value))
    });

  const handleSubmit = () => {
    actions.updateTrack(form);
  };

  const isFetching =
    insights.isFetching || pledges.isFetching || surveys.isFetching || track.isFetching;

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Edit Track</Typography>
      {isEmpty(form) || isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <TrackForm
          {...{
            form,
            insights: insights.insights,
            pledges: pledges.pledges,
            surveys: surveys.surveys,
            handleChange,
            onSubmit: handleSubmit
          }}
        />
      )}
    </div>
  );
};

export default EditTrack;
