import React from 'react';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SURVEY_TYPES, SURVEY_STATE } from 'data/surveys';
import useStyles from './Survey.styles';

const Survey = ({ actions, survey, index }) => {
  const classes = useStyles();

  const questionsPlural = survey.questions.length === 1 ? 'questions' : 'question';
  const questionType = SURVEY_TYPES.filter(type => type.value === survey.type)[0];
  const questionState = SURVEY_STATE.filter(status => status.value === survey.type)[0];
  const questionTypeText = questionType ? questionType.text : '';
  const questionStateText = questionState ? questionState.text : '';
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">{survey.name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.infoContainer}>
          <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              Type:{' '}
            </Typography>
            <Typography variant="body2" display="inline">
              {questionTypeText}
            </Typography>
          </div>
          <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              State:{' '}
            </Typography>
            <Typography variant="body2" display="inline">
              {questionStateText}
            </Typography>
          </div>
          <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              Questions:{' '}
            </Typography>
            <Typography
              variant="body2"
              display="inline"
            >{`${survey.questions.length} ${questionsPlural}`}</Typography>
          </div>
          <Typography variant="h6">{survey.introHeader}</Typography>
          <Typography variant="body1">{survey.introBody}</Typography>
        </div>
      </ExpansionPanelDetails>
      <ExpansionPanelActions>
        <Button size="small" onClick={() => actions.push(`/surveys/${survey.id}/edit`)}>
          Edit
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

export default Survey;
