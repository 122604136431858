import * as data from 'data/pledges';

export const initState = {
  pledges: [],
  isFetching: false,
  lastUpdated: null,
  error: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case data.TYPE_REQUEST_PLEDGES:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case data.TYPE_RECEIVE_PLEDGES:
      return {
        pledges: action.pledges,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        error: null
      };

    case data.TYPE_RECEIVE_PLEDGES_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
