import { TYPE_CREATE_DIALOG, TYPE_DISMISS_DIALOG } from 'data/dialog';

export const createDialog = message => ({
  type: TYPE_CREATE_DIALOG,
  message: message
});

export const dismissDialog = () => ({
  type: TYPE_DISMISS_DIALOG
});
