import {
  TYPE_UPDATE_COLUMN_FILTERS,
  TYPE_REQUEST_INSIGHTS,
  TYPE_RECEIVE_INSIGHTS,
  TYPE_RECEIVE_INSIGHTS_ERROR,
  TYPE_REQUEST_COLUMN_INSIGHTS,
  TYPE_RECEIVE_COLUMN_INSIGHTS,
  TYPE_RECEIVE_COLUMN_INSIGHTS_ERROR,
  TYPE_ADD_COLUMN,
  TYPE_REMOVE_COLUMN,
  TYPE_LOAD_COLUMNS,
  TYPE_PERSIST_COLUMNS
} from 'data/insights';
import { idGenerator } from 'utils/generator';

const INIT_FILTERS = {
  status: [],
  type: []
};

export const emptyColumn = {
  id: null,
  isFetching: false,
  insights: [],
  name: 'Default column',
  filters: { ...INIT_FILTERS }
};

const INIT_COLUMNS = [{ ...emptyColumn }];

export const initState = {
  columns: [...INIT_COLUMNS],
  insights: [],
  isFetching: false
};

let columns = (state = INIT_COLUMNS, action) => {
  switch (action.type) {
    case TYPE_REQUEST_COLUMN_INSIGHTS:
      return state.map(column => {
        if (column.id === action.columnId) {
          return {
            ...column,
            isFetching: action.isFetching
          };
        }
        return column;
      });

    case TYPE_RECEIVE_COLUMN_INSIGHTS:
      return state.map(column => {
        if (column.id === action.columnId) {
          const newInsights =
            action.currentPage !== 1 ? [...column.insights, ...action.insights] : action.insights;
          return {
            ...column,
            isFetching: action.isFetching,
            insights: newInsights,
            nextPage: action.nextPage
          };
        }
        return column;
      });
    case TYPE_RECEIVE_COLUMN_INSIGHTS_ERROR:
      return state.map(column => {
        if (column.id === action.columnId) {
          return {
            ...column,
            isFetching: action.isFetching,
            error: action.error
          };
        }
        return column;
      });

    case TYPE_RECEIVE_INSIGHTS_ERROR:
      return state.map(column => {
        if (column.id === action.columnId) {
          return {
            ...column,
            isFetching: action.isFetching,
            error: action.error
          };
        }
        return column;
      });

    case TYPE_UPDATE_COLUMN_FILTERS:
      return state.map(column => {
        if (column.id === action.columnId) {
          return {
            ...column,
            filters: action.filters
          };
        }
        return column;
      });

    case TYPE_ADD_COLUMN:
      return [
        ...state,
        {
          ...emptyColumn,
          name: action.name,
          id: idGenerator.next().value
        }
      ];

    case TYPE_REMOVE_COLUMN:
      return state.filter(column => column.id !== action.columnId);

    case TYPE_LOAD_COLUMNS: {
      const persistedColumns = JSON.parse(localStorage.getItem('columns'));
      const newColumns = persistedColumns?.length
        ? persistedColumns.map(column => ({
            ...emptyColumn,
            ...column
          }))
        : [emptyColumn];
      return newColumns;
    }

    case TYPE_PERSIST_COLUMNS:
      const persistedColumns = state.map(column => ({
        id: column.id,
        name: column.name,
        filters: column.filters
      }));
      localStorage.setItem('columns', JSON.stringify(persistedColumns));
      return state;

    default:
      return state;
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_COLUMN_INSIGHTS:
    case TYPE_RECEIVE_COLUMN_INSIGHTS:
    case TYPE_RECEIVE_COLUMN_INSIGHTS_ERROR:
    case TYPE_ADD_COLUMN:
    case TYPE_REMOVE_COLUMN:
    case TYPE_LOAD_COLUMNS:
    case TYPE_PERSIST_COLUMNS:
    case TYPE_UPDATE_COLUMN_FILTERS:
      return {
        ...state,
        columns: columns(state.columns, action)
      };

    case TYPE_REQUEST_INSIGHTS:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_INSIGHTS:
      return {
        ...state,
        insights: action.insights,
        isFetching: action.isFetching
      };
    case TYPE_RECEIVE_INSIGHTS_ERROR:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      };

    default:
      return state;
  }
};
