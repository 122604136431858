export const groupLabelsByCluster = labels => {
  const clusters = new Map();
  Object.keys(labels).forEach(id => {
    const label = labels[id];
    const cluster = label.cluster || '';
    if (clusters.has(cluster)) {
      clusters.get(cluster).push(label);
    } else {
      clusters.set(cluster, [label]);
    }
  });
  return clusters;
};
