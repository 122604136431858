import isEmpty from 'lodash/isEmpty';
import { authorizedAxios } from 'config/axios';
import ensure from 'utils/cache';

const cache = ensure('clusters-service');

/**
 * Service that will fetch clusters from the factuary api.
 * @return {Promise}
 */
export const fetchClusters = () => {
  return authorizedAxios.get('/labels/clusters');
};

/**
 * Helper service for fetching segments from a component / view.
 *
 * @param {function} resolve - function to call if the request is successful. Receives an Array.
 * @param {function} reject - function to call if there is an error. Receives a string.
 */
export const handleFetchClusters = () => {
  return new Promise(async (resolve, reject) => {
    const key = cache.makeKey('handleFetchClusters');
    let clusters = cache.get(key);

    if (!isEmpty(clusters)) return resolve({ clusters });

    try {
      const response = await fetchClusters();
      const clusters = response.data.entities;
      cache.set(key, clusters);
      resolve({ clusters });
    } catch (error) {
      reject(error);
    }
  });
};

export const createCluster = cluster => {
  return authorizedAxios.post(`/labels/clusters`, cluster);
};

export const handleCreateCluster = cluster => {
  return new Promise(async (resolve, reject) => {
    const key = cache.makeKey('handleFetchClusters');
    let clusters = cache.get(key);

    try {
      const response = await createCluster(cluster);
      const clusterId = response.data;

      if (!isEmpty(clusters)) {
        const newClusters = {
          ...clusters,
          [clusterId]: cluster.name
        };
        cache.set(key, newClusters);
        return resolve({ clusters: newClusters });
      } else {
        const newClusters = {
          [clusterId]: cluster.name
        };
        cache.set(key, newClusters);
        resolve({ clusters: newClusters });
      }
    } catch (error) {
      reject(error);
    }
  });
};
