import { TYPE_RECEIVE_TENANTS, TYPE_REQUEST_TENANTS } from '../data/tenants';

export const initState = {
  tenants: [],
  isFetching: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_TENANTS:
      return { ...state, isFetching: true };

    case TYPE_RECEIVE_TENANTS:
      return {
        ...state,
        tenants: action.tenants,
        isFetching: false
      };

    default:
      return state;
  }
};
