import isEmpty from 'lodash/isEmpty';

import store from 'config/store';
import * as service from 'services/tenants';
import { TYPE_REQUEST_TENANTS, TYPE_RECEIVE_TENANTS } from 'data/tenants';

const requestTenants = () => ({ type: TYPE_REQUEST_TENANTS, isFetching: true });

const receiveTenants = json => ({
  type: TYPE_RECEIVE_TENANTS,
  tenants: json.map(tenant => ({
    id: tenant.tid,
    name: tenant.common_name
  })),
  isFetching: false
});

const fetchTenants = () => {
  return dispatch => {
    dispatch(requestTenants());
    return service
      .fetchTenants()
      .then(response => dispatch(receiveTenants(response)))
      .catch(() => {
        // TODO: Display something with error message
      });
  };
};

export const fetchTenantsIfNeeded = () => {
  return (dispatch, getState) => {
    const state = store.getState();
    const tenants = state.tenants;
    const isFetching = tenants.isFetching;
    if (isEmpty(tenants.tenants) && !isFetching) {
      return dispatch(fetchTenants());
    }
  };
};
