import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import PledgeForm from 'components/views/pledges/form';

import useStyles from './EditPledge.styles';

const EditPledge = ({ actions, facts, pledge, pledgeId, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  useEffect(() => {
    actions.onMount();
    actions.fetchPledge(pledgeId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isEmpty(pledge.pledge)) setForm(pledge.pledge);
  }, [pledge.pledge]);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleCheck = name => ({ target: { checked } }) => {
    setForm({
      ...form,
      [name]: checked
    });
  };

  const handleTenantCheck = (tenantIndex) => ({ target: { checked } }) => {
    let tenantList = form.tenants || [];
    const selectedId = tenants.tenants[tenantIndex].id;
    if(checked) {
      tenantList.push(selectedId);
    } else {
      tenantList = tenantList.filter(tenant => tenant !== selectedId);
    }
    setForm({
      ...form,
      tenants: tenantList
    });
  };

  const handleSubmit = () => {
    actions.updatePledge(form).catch(console.log);
  };

  const { testPassed, testResults } = pledge;

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit Pledge</Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={testPassed} testResults={testResults} />
        </div>
      </div>
      {isEmpty(form) || pledge.isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <PledgeForm
          facts={facts}
          tenants={tenants}
          form={form}
          handleChange={handleChange}
          handleCheck={handleCheck}
          handleTenantCheck={handleTenantCheck}
          labels={labels}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default EditPledge;
