import React, { useState, useEffect, useReducer } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Button, Grid, IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import Checkbox from 'components/commons/checkbox';

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_INITIAL_CONDITIONS':
      return action.payload;
    case 'ADD_CONDITION':
      return [...state, action.payload];
    case 'DELETE_CONDITION':
      return state.filter((_, index) => index !== action.payload);
    case 'ADD_CONDITION_FACT': {
      let localState = state;
      const { conditionKey, value } = action.payload;
      const condition = localState[conditionKey];
      localState[conditionKey]['facts'] = [...condition.facts, value];
      return [...localState];
    }
    case 'DELETE_CONDITION_FACT': {
      let localState = state;
      const { conditionKey, factKey } = action.payload;
      const condition = localState[conditionKey];
      localState[conditionKey]['facts'] = condition.facts.filter((_, index) => index !== factKey);
      return [...localState];
    }
    case 'CHANGE_CONDITION_FACT_NAME': {
      let localState = state;
      const { conditionKey, factKey, value } = action.payload;
      localState[conditionKey]['facts'][factKey][0] = value;
      return [...localState];
    }
    case 'CHANGE_CONDITION_FACT_VALUE': {
      let localState = state;
      const { conditionKey, factKey, value } = action.payload;
      localState[conditionKey]['facts'][factKey][1] = value;
      return [...localState];
    }
    case 'CHANGE_CONDITION_EXPECTED': {
      let localState = state;
      const { conditionKey, value } = action.payload;
      localState[conditionKey]['expected'] = value;
      return [...localState];
    }
    default:
      return state;
  }
};

const initialState = [];

const TestConditions = ({ classes, initialValue, onChange }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      if (initialValue.length) {
        dispatch({
          type: 'GET_INITIAL_CONDITIONS',
          payload: initialValue.map(condition => ({
            expected: condition.expected,
            facts: Object.entries(condition.facts),
          })),
        });
        setGotInitialValue(true);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);
  useEffect(() => {
    onChange(state);
  }, [onChange, state]);

  const handleAddCondition = () => {
    const newCondition = {
      expected: false,
      facts: [['', '']],
    };
    dispatch({ type: 'ADD_CONDITION', payload: newCondition });
  };

  const handleDeleteCondition = conditionKey => {
    dispatch({ type: 'DELETE_CONDITION', payload: conditionKey });
  };

  const handleAddFact = conditionKey => {
    dispatch({ type: 'ADD_CONDITION_FACT', payload: { conditionKey, value: ['', ''] } });
  };

  const handleDeleteFact = (conditionKey, factKey) => {
    dispatch({ type: 'DELETE_CONDITION_FACT', payload: { conditionKey, factKey } });
  };

  const handleCheckChange = (conditionKey, factKey) => ({ target: { checked } }) => {
    dispatch({
      type: 'CHANGE_CONDITION_EXPECTED',
      payload: {
        conditionKey,
        factKey,
        value: checked,
      },
    });
  };

  const handleFactNameChange = (conditionKey, factKey) => ({ target: { value } }) => {
    dispatch({
      type: 'CHANGE_CONDITION_FACT_NAME',
      payload: {
        conditionKey,
        factKey,
        value,
      },
    });
  };

  const handleFactValueChange = (conditionKey, factKey) => ({ target: { value } }) => {
    dispatch({
      type: 'CHANGE_CONDITION_FACT_VALUE',
      payload: {
        conditionKey,
        factKey,
        value,
      },
    });
  };

  const renderCondition = (condition, conditionKey) => {
    return (
      <Box key={conditionKey} mt={1} mb={1}>
        {condition.facts.map(([fact, value], factKey) => (
          <Grid key={factKey} container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <TextField
                id={`condition-fact-${conditionKey}`}
                type="text"
                value={fact}
                onChange={handleFactNameChange(conditionKey, factKey)}
                margin="normal"
                label="Fact"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.inputFont,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id={`condition-fact-value-${conditionKey}`}
                type="text"
                value={value}
                onChange={handleFactValueChange(conditionKey, factKey)}
                margin="normal"
                label="Value"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton onClick={() => handleDeleteFact(conditionKey, factKey)} size="small">
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Checkbox
              checked={condition.expected}
              label="Test should be true"
              onChange={handleCheckChange(conditionKey)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button onClick={() => handleAddFact(conditionKey)}>
              <AddIcon />
              Add fact
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button onClick={() => handleDeleteCondition(conditionKey)}>
              <ClearIcon />
              Delete condition test
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {state.map(renderCondition)}
      <Button onClick={() => handleAddCondition()} color="secondary">
        Add Condition Test
      </Button>
    </>
  );
};

export default TestConditions;
