import {
  TYPE_REQUEST_LABEL,
  TYPE_RECEIVE_LABEL_SUCCESS,
  TYPE_RECEIVE_LABEL_ERROR,
  TYPE_UPDATE_LABEL,
  TYPE_UPDATE_LABEL_SUCCESS,
  TYPE_UPDATE_LABEL_ERROR,
  TYPE_CREATE_LABEL,
  TYPE_CREATE_LABEL_SUCCESS,
  TYPE_CREATE_LABEL_ERROR,
  TYPE_COPY_LABEL,
  TYPE_RESET_LABEL
} from 'data/labels';

export const initialState = {
  label: {},
  isFetching: false,
  lastUpdated: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_LABEL:
      return {
        ...state,
        isFetching: true,
        lastUpdated: null,
        label: action.label,
        error: null
      };

    case TYPE_RECEIVE_LABEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        label: action.label,
        error: null
      };

    case TYPE_UPDATE_LABEL:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        error: null
      };

    case TYPE_UPDATE_LABEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        label: action.label,
        error: null
      };

    case TYPE_RECEIVE_LABEL_ERROR:
    case TYPE_UPDATE_LABEL_ERROR:
    case TYPE_CREATE_LABEL_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case TYPE_CREATE_LABEL:
      return {
        ...state,
        isFetching: false,
        label: action.label,
        error: null
      };

    case TYPE_CREATE_LABEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        label: action.label,
        error: null
      };

    case TYPE_COPY_LABEL:
      return {
        ...state,
        label: {
          ...action.label,
          id: null,
          name: `Copy of ${action.label.name}`,
          flags: 0 // just in case
        },
        isFetching: false,
        lastUpdated: null,
        error: null
      };

    case TYPE_RESET_LABEL:
      return initialState;

    default:
      return state;
  }
};
