import React, { useEffect } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Sidebar from 'components/templates/sidebar';
import Survey from 'components/views/surveys/survey';
import useStyles from './Surveys.styles';

const Surveys = ({ actions, surveys }) => {
  const classes = useStyles();
  useEffect(() => {
    actions.onMount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Surveys"
        mainButtonAction={() => actions.push('/surveys/create')}
        mainButtonText="Create survey"
      />
      <div className={classes.surveysContainer}>
        {surveys.isFetching ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          surveys.surveys.length ? (
            surveys.surveys.map((survey, index) => <Survey key={index} {...{ actions, survey }} />)
          ) : (
            <div className={classes.noSurveysContainer}>
              <WarningIcon color="disabled" fontSize="large" />
              <Typography variant="body1">
                While fundamentally good, there are no results for your chosen filters. Please alter
                your filters to try again.
              </Typography>
            </div>
        ))}
      </div>
    </div>
  );
};

export default Surveys;
