import React from 'react';

import AppBar from 'components/commons/app-bar';

const HomeTemplate = ({ actions, children, location, ...props }) => (
  <>
    <AppBar 
      onClickLogout={actions.logout}
      activeRoute={location.pathname}
    />
    {children}
  </>
);

export default HomeTemplate;
