import React from 'react';
import AceEditor from 'react-ace';

import 'brace/mode/lisp';
import 'brace/mode/python';
import 'brace/mode/html';
import 'brace/theme/xcode';

export const Clips = ({ name, value = '', disabled, onChange, ...restProps }) => {
  return (
    <AceEditor
      name={name}
      mode="lisp"
      theme="xcode"
      maxLines={10}
      readOnly={disabled}
      width="100%"
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};

export const Python = ({ name, value = '', disabled, onChange, restProps }) => {
  return (
    <AceEditor
      name={name}
      mode="python"
      theme="xcode"
      maxLines={10}
      readOnly={disabled}
      width="100%"
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};

export const Html = ({ name, value = '', disabled, onChange, restProps }) => {
  return (
    <AceEditor
      name={name}
      mode="html"
      theme="xcode"
      maxLines={10}
      readOnly={disabled}
      width="100%"
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};
