export const TYPE_REQUEST_USER = 'user/REQUEST_USER'
export const TYPE_RECEIVE_USER = 'user/RECEIVE_USER'

export const LOGIN_STATUS_UNKNOWN = 'user/USER_UNKNOWN'
export const LOGIN_STATUS_LOGGEDIN = 'user/USER_LOGGEDIN'
export const LOGIN_STATUS_LOGGEDOUT = 'user/USER_LOGGEDOUT'
export const LOGIN_STATUS_REJECTED = 'user/USER_REJECTED'

export const initState = {
    name: null,
    email: null,
    image: null,
    lastUpdated: null,
    isFetching: false,
    loginStatus: LOGIN_STATUS_UNKNOWN
}

export default (state = initState, action) => {
  switch(action.type) {
    case TYPE_REQUEST_USER:
      return {
        ...state,
        isFetching: true,
        loginStatus: LOGIN_STATUS_UNKNOWN
      };

    case TYPE_RECEIVE_USER:
      const { name, email, image, lastUpdated, loginStatus } = action.payload;
      return {
        ...state,
        name,
        email,
        image,
        lastUpdated,
        isFetching: false,
        loginStatus
      };

    default:
      return state;
  }
}
