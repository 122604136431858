import {
  TYPE_REQUEST_LABELS,
  TYPE_RECEIVE_LABELS_SUCCESS,
  TYPE_RECEIVE_LABELS_CACHED,
  TYPE_REQUEST_CLUSTERS,
  TYPE_RECEIVE_CLUSTERS,
  TYPE_CREATE_CLUSTER,
  TYPE_CREATE_CLUSTER_SUCCESS
} from 'data/labels';

const INIT_FILTERS = {
  facts: null,
  query: null
};

export const initState = {
  clusters: {},
  labels: {},
  isFetching: false,
  lastUpdated: null,
  fetchType: null,
  filter: INIT_FILTERS
};

const filters = (state = INIT_FILTERS, action) => {
  switch (action.type) {
    case TYPE_RECEIVE_LABELS_SUCCESS:
    case TYPE_RECEIVE_LABELS_CACHED:
      return { ...state, ...action.filter };
    default:
      return state;
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_LABELS:
    case TYPE_REQUEST_CLUSTERS:
    case TYPE_CREATE_CLUSTER:
      return {
        ...state,
        isFetching: true
      };
    case TYPE_RECEIVE_LABELS_SUCCESS:
    case TYPE_RECEIVE_LABELS_CACHED:
      return {
        ...state,
        isFetching: false,
        labels: action.labels,
        lastUpdated: action.lastUpdated,
        fetchType: action.fetchType,
        filter: filters(action.filter, action)
      };
    case TYPE_RECEIVE_CLUSTERS:
      return {
        ...state,
        clusters: action.clusters
      };
    case TYPE_CREATE_CLUSTER_SUCCESS:
      return {
        ...state,
        clusters: {
          ...state.clusters,
          [action.clusterId]: action.clusterName
        }
      };
    default:
      return state;
  }
};
