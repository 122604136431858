export const AUTOLOGIN_REQUESTED = 'auth/AUTOLOGIN_REQUESTED';
export const LOGIN_REQUESTED = 'auth/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';
export const LOGOUT_REQUESTED = 'auth/LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';

const initialState = {
  isAuthed: false,
  isLoading: false,
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTOLOGIN_REQUESTED:
    case LOGIN_REQUESTED:
    case LOGOUT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthed: true,
        isLoading: false,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        isAuthed: false,
        isLoading: false,
        user: {},
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state
  }
}
