import nanoid from 'nanoid';

function* generateId() {
  do {
    const newId = nanoid(10);
    yield newId;
  } while (true);
}

export const idGenerator = generateId();
