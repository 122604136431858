import React, { useState } from 'react';
import { Button as MUIButton, Grid, TextField } from '@material-ui/core';

import Button from 'components/commons/button';
import Select from 'components/commons/select';
import Form from 'components/commons/form';
import LabelConditions from '../label-conditions';
import LabelTests from '../label-tests';
import ClusterDialog from '../cluster-dialog';
import useStyles from './Form.styles';

const LabelForm = ({ clusters, form, handleChange, onCreateCluster, onSubmit }) => {
  const classes = useStyles();
  const [openClusterDialog, setOpenClusterDialog] = useState(false);
  const [newCluster, setNewCluster] = useState({
    name: ''
  });

  const handleNewClusterChange = name => e =>
    setNewCluster({
      ...newCluster,
      [name]: e.target.value
    });

  const handleCreateNewCluster = () => {
    onCreateCluster(newCluster);
  };

  const handleSubmit = () => onSubmit();

  const clusterOptions = Object.entries(clusters).map(([clusterId, clusterName]) => ({
    value: clusterId,
    text: clusterName
  }));

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            id="name"
            label="Unique Label Name (Key)"
            type="text"
            value={form.name}
            onChange={handleChange('name')}
            margin="normal"
            fullWidth
            helperText="Label names are usually a logic statement including the facts used in the label."
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            label="Cluster"
            id="cluster"
            onChange={handleChange('cluster')}
            value={form.cluster}
            options={clusterOptions}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <MUIButton color="secondary" onClick={() => setOpenClusterDialog(true)}>
        Add Cluster
      </MUIButton>
      <ClusterDialog
        open={openClusterDialog}
        value={newCluster}
        onChange={handleNewClusterChange}
        onClose={() => setOpenClusterDialog(false)}
        onSubmit={handleCreateNewCluster}
      />
      <Grid className={classes.sectionContainer} container spacing={2}>
        <Grid item xs={12} md={8}>
          <LabelConditions handleChange={handleChange} initialValue={form.conditions} />
        </Grid>
      </Grid>
      <Grid className={classes.sectionContainer} container spacing={2}>
        <Grid item xs={12} md={8}>
          <LabelTests handleChange={handleChange} initialValue={form.tests} />
        </Grid>
      </Grid>
      <Button color="primary" variant="contained" type="submit" size="medium">
        Save
      </Button>
    </Form>
  );
};

export default LabelForm;
