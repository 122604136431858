import React from 'react';
import {
  IconButton,
  Snackbar as MUISnackbar,
  SnackbarContent as MUISnackbarContent
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon
} from '@material-ui/icons';
import classNames from 'classnames';

import useStyles from './Snackbar.styles';


const Snackbar = ({ message, open, onClose, variant }) => {
  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <SnackbarContent
        variant={variant}
        message={message}
        onClose={onClose}
      />
    </MUISnackbar>
  );
};


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const SnackbarContent = ({ className, message, onClose, variant, ...other }) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  return (
    <MUISnackbarContent
      className={classNames(classes[variant], className)}
      message={
        <span className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      }
      {...other}
    />
  );
}

export default Snackbar;

