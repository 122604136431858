import isEmpty from 'lodash/isEmpty';

import store from 'config/store';
import * as service from 'services/facts';
import { TYPE_REQUEST_FACTS, TYPE_RECEIVE_FACTS } from 'data/facts';

const requestFacts = () => ({ type: TYPE_REQUEST_FACTS, isFetching: true });

const receiveFacts = json => ({
  type: TYPE_RECEIVE_FACTS,
  facts: json.data,
  lastUpdated: json.meta.time,
  isFetching: false
});

const fetchFacts = () => {
  return dispatch => {
    dispatch(requestFacts());
    return service
      .fetchFacts()
      .then(response => dispatch(receiveFacts(response)))
      .catch(() => {
        // TODO: Display something with error message
      });
  };
};

export const fetchFactsIfNeeded = () => {
  return (dispatch, getState) => {
    const state = store.getState();
    const facts = state.facts;
    const isFetching = facts.isFetching;
    if (isEmpty(facts.facts) && !isFetching) {
      return dispatch(fetchFacts());
    }
  };
};
