import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});
